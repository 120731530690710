
#id6490d4afa52ebcacc7a80394 {
  #ivee {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : space-around; 
align-items : center; 
} 
#i67f {
color : black; 
width : 252px; 
height : 251.67972500000002px; 
z-index : 10; 
margin : auto auto auto auto; 
float : right; 
} 
#iq9l {
padding : 10px; 
width : 76.79%; 
display : flex; 
align-items : center; 
justify-content : space-around; 
} 
#i5tql {
color : #d983a6; 
padding : 5px; 
display : block; 
} 
#i1n85 {
width : auto; 
color : #df9405; 
font-size : 16px; 
font-family : 'Baloo 2', cursive; 
} 
#if57z {
width : auto; 
color : #df9405; 
font-size : 16px; 
font-family : 'Baloo 2', cursive; 
} 
#ikkdy {
color : #d983a6; 
padding : 5px; 
display : block; 
} 
#iqceg {
width : auto; 
color : #df9405; 
font-size : 16px; 
font-family : 'Baloo 2', cursive; 
} 
#ifv1s {
color : #d983a6; 
padding : 5px; 
display : block; 
} 
#islmj {
padding : 10px; 
min-height : 41px; 
width : 35%; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#f2f7ff 0%, #f2f7ff 100%); 
border-radius : 12px 12px 12px 12px; 
display : flex; 
flex-direction : row; 
height : 60px; 
} 
.standard_button {
text-align : center; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
display : inline-block; 
border-top-width : 1px; 
border-right-width : 1px; 
border-bottom-width : 1px; 
border-left-width : 1px; 
border-top-style : solid; 
border-right-style : solid; 
border-bottom-style : solid; 
border-left-style : solid; 
border-top-color : rgb(0, 119, 204); 
border-right-color : rgb(0, 119, 204); 
border-bottom-color : rgb(0, 119, 204); 
border-left-color : rgb(0, 119, 204); 
border-image-source : initial; 
border-image-slice : initial; 
border-image-width : initial; 
border-image-outset : initial; 
border-image-repeat : initial; 
font-size : 20px; 
margin-top : 4px; 
margin-right : 2px; 
margin-bottom : 4px; 
margin-left : 2px; 
cursor : pointer; 
color : rgb(255, 255, 255); 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#3f7696 0%, #3f7696 100%); 
border-radius : 50% 50% 50% 50%; 
padding : 15px 15px 15px 15px; 
} 
.standard_button:disabled  {
background-color : rgb(226, 229, 222); 
color : gray; 
} 
.standard_button:hover  {
background-image : linear-gradient(rgb(81, 169, 238), rgb(20, 123, 205)); 
border-top-color : rgb(20, 130, 208); 
border-right-color : rgb(20, 130, 208); 
border-bottom-color : rgb(20, 130, 208); 
border-left-color : rgb(20, 130, 208); 
color : rgb(255, 255, 255); 
} 
.standard_button:active  {
background-image : radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%); 
border-top-color : rgb(0, 109, 188); 
border-right-color : rgb(0, 109, 188); 
border-bottom-color : rgb(0, 109, 188); 
border-left-color : rgb(0, 109, 188); 
color : rgb(255, 255, 255); 
} 
#inqxd {
padding : 10px; 
width : 175px; 
min-height : 60px; 
display : flex; 
justify-content : space-around; 
} 
.gjs-container {
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
min-height : 100px; 
display : block; 
} 
#ijy2n {
color : black; 
width : 30px; 
height : auto; 
} 
#i6hs3 {
width : auto; 
min-height : 40px; 
display : block; 
padding : 5px 5px 5px 5px; 
} 
#ipjgg {
padding : 0px 0px 0px 0px; 
min-height : 550px; 
height : auto; 
} 
.swiper {
width : 100%; 
height : 300px; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
} 
.swiper.mySwiper {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#3f7696 0%, #3f7696 100%); 
height : auto; 
padding : 0px 0px 0px 0px; 
} 
.swiper-slide {
height : auto; 
width : 100vw; 
margin : -10px 0px -10px 0px; 
} 
.href_button {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
text-align : center; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
} 
.href_button:disabled  {
background-color : rgb(226, 229, 222); 
color : gray; 
} 
.href_button:hover  {
background-image : linear-gradient(rgb(81, 169, 238), rgb(20, 123, 205)); 
border-top-color : rgb(20, 130, 208); 
border-right-color : rgb(20, 130, 208); 
border-bottom-color : rgb(20, 130, 208); 
border-left-color : rgb(20, 130, 208); 
color : rgb(255, 255, 255); 
} 
.href_button:active  {
background-image : radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%); 
border-top-color : rgb(0, 109, 188); 
border-right-color : rgb(0, 109, 188); 
border-bottom-color : rgb(0, 109, 188); 
border-left-color : rgb(0, 109, 188); 
color : rgb(255, 255, 255); 
} 
.swiper-button-next {
color : white; 
} 
.swiper-button-prev {
color : white; 
} 
#i7l1k {
padding : 10px; 
display : block; 
color : white; 
text-align : center; 
font-size : 13px; 
font-family : 'Baloo 2', cursive; 
} 
#ig891 {
padding : 10px; 
min-height : auto; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#3f7696 0%, #3f7696 100%); 
margin : 0px 0px 0px 0px; 
} 
#ie6sa {
padding : 10px; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#f2f7ff 0%, #f2f7ff 100%); 
} 
#iqobg {
padding : 10px; 
min-height : 100px; 
} 
#ibllc {
padding : 10px; 
display : block; 
font-size : 42px; 
font-family : 'Baloo 2', cursive; 
font-weight : 700; 
text-align : center; 
color : #14569e; 
} 
#ihuk4 {
padding : 10px; 
min-height : 100px; 
display : flex; 
flex-wrap : wrap; 
} 
#iu87j {
padding : 10px; 
min-height : 100px; 
width : 50%; 
text-align : right; 
} 
#i1i8k {
color : black; 
width : 75px; 
height : 75px; 
margin : 0px 0px 0px 0px; 
z-index : 1; 
} 
#iwg4f {
padding : 10px; 
min-height : 100px; 
width : 50%; 
} 
#irgjju {
display : flex; 
align-items : center; 
padding : 0px 10px 0px 10px; 
width : 417.328125px; 
} 
#iqcul1 {
color : black; 
width : 37px; 
height : 37px; 
} 
#iqq4mu {
padding : 10px; 
display : block; 
font-family : 'Baloo 2', cursive; 
font-weight : 700; 
color : #024d67; 
font-size : 22px; 
} 
#ih8ro2 {
color : black; 
width : 37px; 
height : 37px; 
} 
#ivdrgi {
padding : 10px; 
display : block; 
font-family : 'Baloo 2', cursive; 
font-weight : 700; 
color : #024d67; 
font-size : 22px; 
} 
#i0mmmx {
display : flex; 
align-items : center; 
padding : 0px 10px 0px 10px; 
width : 419.86328125px; 
} 
#iu0rp5 {
color : black; 
width : 37px; 
height : 37px; 
} 
#iqzql2 {
padding : 10px; 
display : block; 
font-family : 'Baloo 2', cursive; 
font-weight : 700; 
color : #024d67; 
font-size : 22px; 
} 
#ii2lsw {
display : flex; 
align-items : center; 
padding : 0px 10px 0px 10px; 
width : 423.484375px; 
} 
#iae27s {
color : black; 
width : 37px; 
height : 37px; 
} 
#ikdl4l {
padding : 10px; 
display : block; 
font-family : 'Baloo 2', cursive; 
font-weight : 700; 
color : #024d67; 
font-size : 22px; 
} 
#i5vgyt {
display : flex; 
align-items : center; 
padding : 0px 10px 0px 10px; 
width : 421.12109375px; 
} 
#iu1vxq {
padding : 0px 0px 0px 0px; 
min-height : 100px; 
display : flex; 
} 
#i9lqvz {
padding : 0px 0px 0px 0px; 
min-height : 100px; 
width : 50%; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#f6f8fc 0%, #f6f8fc 100%); 
} 
#ibv7jz {
padding : 15% 10px 15% 10px; 
position : relative; 
height : 818.375px; 
} 
#i4qyhg {
color : black; 
width : 188px; 
height : 157px; 
position : absolute; 
top : auto; 
left : auto; 
right : 0px; 
bottom : 0px; 
} 
#ija8qb {
color : black; 
width : 188px; 
height : 157px; 
position : absolute; 
top : 0px; 
left : 0px; 
} 
#i38vsn {
padding : 10px; 
min-height : 400px; 
width : 300px !important; 
border-radius : 17px 17px 17px 17px; 
box-shadow : 0px 15px 5px 2px rgba(0,0,0,0.14) ; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(white 0%, white 100%); 
margin : 15px 15px 15px 15px; 
} 
#itq462 {
color : black; 
margin : auto auto auto auto; 
float : center; 
width : 75%; 
} 
#idv4os {
padding : 10px; 
display : block; 
margin : 10px 0px 10px 0px; 
color : #14569e; 
font-family : 'Baloo 2', cursive; 
font-weight : 600; 
font-size : 26px; 
text-align : center; 
} 
#ijll8y {
padding : 5px 5px; 
text-align : center; 
display : inline-block; 
min-width : 50px; 
min-height : 30px; 
cursor : pointer; 
color : #fff; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#df9405 0%, #df9405 100%); 
border : 1px solid white; 
border-radius : 5px 5px 5px 5px; 
margin : 0px 0px 0px 0px; 
width : 100%; 
} 
#is4vch {
text-align : center; 
} 
#i3wp2n {
padding : 10% 10px 10% 0px; 
min-height : 100px; 
position : relative; 
} 
#iq6mrf {
padding : 20px 10px 20px 10px; 
min-height : 100px; 
width : 75%; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#df9405 0%, #df9405 100%); 
border-radius : 0px 40px 40px 0px; 
} 
#iav37s {
padding : 10px; 
display : block; 
color : #14569e; 
font-family : 'Baloo 2', cursive; 
font-weight : 700; 
font-size : 53px; 
} 
#i66i0t {
padding : 10px; 
display : block; 
font-size : 22px; 
font-family : 'Baloo 2', cursive; 
color : white; 
font-weight : 600; 
} 
#imx1a4 {
padding : 10px; 
min-height : 100px; 
display : flex; 
flex-wrap : wrap; 
justify-content : space-around; 
align-self : center; 
align-items : center; 
} 
#ixu5m4 {
color : black; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(white 0%, white 100%); 
border-radius : 36px 36px 36px 36px; 
width : 300px; 
} 
#i35c9u {
color : black; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(white 0%, white 100%); 
border-radius : 36px 36px 36px 36px; 
width : 300px; 
} 
#ig4vw5 {
color : black; 
position : absolute; 
bottom : 0px; 
right : 0px; 
width : 392.37890625px; 
height : 592px; 
} 
@media only screen and (max-width: 992px) {#ig4vw5 {
width : 234px; 
height : 353.23828125px; 
}} 
@media only screen and (max-width: 992px) {#i1i8k {
width : auto; 
}} 
@media only screen and (max-width: 992px) {#inqxd {
width : 106.757375px; 
}} 
@media only screen and (max-width: 992px) {#i38vsn {
margin : 10px 10px 10px 10px; 
}} 
@media only screen and (max-width: 992px) {#i66i0t {
font-size : 16px; 
}} 
@media only screen and (max-width: 992px) {#iav37s {
font-size : 33px; 
}} 
@media only screen and (max-width: 992px) {#ixu5m4 {
width : 200px; 
height : 39px; 
}} 
@media only screen and (max-width: 992px) {#i35c9u {
width : 200px; 
}} 
@media only screen and (max-width: 992px) {#ibllc {
font-size : 22px; 
}} 
@media only screen and (max-width: 992px) {#iqq4mu {
font-size : 16px; 
}} 
@media only screen and (max-width: 992px) {#ikdl4l {
font-size : 16px; 
}} 
@media only screen and (max-width: 992px) {#iqzql2 {
font-size : 16px; 
}} 
@media only screen and (max-width: 992px) {#ivdrgi {
font-size : 16px; 
}} 
@media only screen and (max-width: 992px) {#i0mmmx {
width : 329.155875px; 
}} 
@media only screen and (max-width: 992px) {#i67f {
width : 177.265625px; 
height : 177px; 
}} 
@media only screen and (max-width: 480px) {#iu87j {
width : 100%; 
text-align : center; 
display : flex; 
}} 
@media only screen and (max-width: 480px) {#iq9l {
display : none; 
}} 
@media only screen and (max-width: 480px) {#i1i8k {
height : 75px; 
display : block; 
}} 
@media only screen and (max-width: 480px) {#ipjgg {
height : auto; 
}} 
@media only screen and (max-width: 480px) {#i9lqvz {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#iu1vxq {
flex-wrap : wrap; 
}} 
@media only screen and (max-width: 480px) {#ija8qb {
display : none; 
}} 
@media only screen and (max-width: 480px) {#ibv7jz {
height : auto; 
}} 
@media only screen and (max-width: 480px) {#i4qyhg {
display : none; 
}} 
@media only screen and (max-width: 480px) {#ig4vw5 {
position : static; 
width : 100%; 
height : auto; 
}} 
@media only screen and (max-width: 480px) {#iq6mrf {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i35c9u {
margin : 10px 0 0 0; 
}} 
@media only screen and (max-width: 480px) {#i67f {
float : center; 
text-align : center; 
}} 
@media only screen and (max-width: 480px) {#iwg4f {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#iqq4mu {
font-size : 10px; 
}} 
@media only screen and (max-width: 480px) {#ikdl4l {
font-size : 10px; 
}} 
@media only screen and (max-width: 480px) {#iqzql2 {
font-size : 10px; 
}} 
@media only screen and (max-width: 480px) {#ivdrgi {
font-size : 10px; 
}} 
@media only screen and (max-width: 480px) {#i0mmmx {
width : 233.14018750000002px; 
}} 
@media only screen and (max-width: 480px) {#ii2lsw {
width : 170.71446875px; 
}} 
@media only screen and (max-width: 480px) {#i5vgyt {
width : 150.11309375px; 
}} 
@media only screen and (max-width: 480px) {#irgjju {
width : 141.49596875px; 
}} 
.swiper-wrapper {
height : auto; 
} 
#i1dgzw {
z-index : 2; 
} 
#ie5nhm {
padding : 10px; 
min-height : 100px; 
display : flex; 
} 
#ie689e {
padding : 10px; 
min-height : 100px; 
width : 20%; 
} 
#i65p1t {
padding : 10px; 
min-height : 100px; 
width : 80%; 
} 
#if5yhs {
color : black; 
width : 100%; 
} 
#i3eq1x {
padding : 10px; 
display : block; 
font-weight : 400; 
font-size : 14px; 
font-family : 'Baloo 2', cursive; 
} 
#i49z3v {
color : rgb(51, 51, 49); 
font-family : "Open Sans", sans-serif; 
font-size : 14px; 
background-color : rgb(246, 248, 252); 
} 
#i4kthf {
color : black; 
width : 100%; 
} 
#ivdf7m {
padding : 10px; 
min-height : 100px; 
width : 20%; 
} 
#iuhn6d {
color : rgb(51, 51, 49); 
font-family : "Open Sans", sans-serif; 
font-size : 14px; 
background-color : rgb(246, 248, 252); 
} 
#i9ckii {
padding : 10px; 
display : block; 
font-weight : 400; 
font-size : 14px; 
font-family : 'Baloo 2', cursive; 
} 
#ik8dha {
padding : 10px; 
min-height : 100px; 
width : 80%; 
} 
#i33tx7 {
padding : 10px; 
min-height : 100px; 
display : flex; 
} 
#i3lfbj {
padding : 0px 0px 0px 0px; 
min-height : 100px; 
width : 50%; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#f6f8fc 0%, #f6f8fc 100%); 
} 
@media only screen and (max-width: 480px) {#i3lfbj {
width : 100%; 
}} 
#i8mf21 {
color : black; 
width : 100%; 
} 
#ix3xjx {
padding : 10px; 
min-height : 100px; 
width : 20%; 
} 
#iumwdo {
color : rgb(51, 51, 49); 
font-family : "Open Sans", sans-serif; 
font-size : 14px; 
background-color : rgb(246, 248, 252); 
} 
#iqocr9 {
padding : 10px; 
display : block; 
font-weight : 400; 
font-size : 14px; 
font-family : 'Baloo 2', cursive; 
} 
#i77i5m {
padding : 10px; 
min-height : 100px; 
width : 80%; 
} 
#ili17q {
padding : 10px; 
min-height : 100px; 
display : flex; 
} 
#isd1bb {
padding : 0px 0px 0px 0px; 
min-height : 100px; 
width : 50%; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#f6f8fc 0%, #f6f8fc 100%); 
} 
@media only screen and (max-width: 480px) {#isd1bb {
width : 100%; 
}} 
#i8xtmi {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : space-around; 
flex-wrap : wrap; 
margin : -200px 0 0 0; 
} 
#iwhs47 {
padding : 10px; 
min-height : 100px; 
height : 557px; 
display : flex; 
align-items : center; 
flex-wrap : wrap; 
justify-content : center; 
} 
#i366pl {
padding : 10px; 
min-height : 100px; 
width : 484px; 
} 
#i1raze {
padding : 10px 10px 10px 10px; 
min-height : 100px; 
width : 484px; 
display : flex; 
flex-wrap : wrap; 
justify-content : space-around; 
align-items : center; 
} 
#iz8dge {
padding : 10px; 
display : block; 
font-family : 'Baloo 2', cursive; 
font-weight : 700; 
color : #14569e; 
font-size : 42px; 
} 
#ihmz9a {
font-size : 18px; 
font-family : 'Baloo 2', cursive; 
} 
#iaqbuh {
padding : 10px; 
display : block; 
color : #14569e; 
font-family : 'Baloo 2', cursive; 
font-weight : 600; 
font-size : 18px; 
text-align : center; 
margin : 0 0 20px 0; 
} 
#ihcii {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#3f7696 0%, #3f7696 100%); 
color : white; 
padding : 5px 5px 5px 5px; 
border-radius : 10px 10px 10px 10px; 
} 
@media only screen and (max-width: 992px) {#iwhs47 {
height : auto; 
}} 
@media only screen and (max-width: 992px) {#ibv7jz {
min-height : 818.375px; 
height : auto; 
}} 
@media only screen and (max-width: 992px) {#i8xtmi {
margin : 0px 0px 0px 0px; 
}} 
@media only screen and (max-width: 992px) {#iz8dge {
text-align : center; 
}} 
@media only screen and (max-width: 480px) {#iz8dge {
font-size : 33px; 
}} 
#ivb27 {
font-family : 'Baloo 2', cursive; 
} 
#itrply {
padding : 10px; 
} 
#io6doc {
color : black; 
width : 100%; 
} 
#iwhr2a {
color : black; 
width : 100%; 
} 
#i8pprf {
padding : 10px; 
} 
#iqta9u {
color : black; 
width : 100%; 
} 
#iavs5h {
padding : 10px; 
} 
#i9w2dm {
color : black; 
width : 100%; 
} 
#iipxxk {
padding : 10px; 
} 
.inputContainer {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
} 
.formInput {
background-image : initial; 
background-position-x : initial; 
background-position-y : initial; 
background-size : initial; 
background-repeat-x : initial; 
background-repeat-y : initial; 
background-attachment : initial; 
background-origin : initial; 
background-clip : initial; 
background-color : transparent; 
border-top-width : initial; 
border-right-width : initial; 
border-left-width : initial; 
border-top-style : none; 
border-right-style : none; 
border-left-style : none; 
border-top-color : initial; 
border-right-color : initial; 
border-left-color : initial; 
border-image-source : initial; 
border-image-slice : initial; 
border-image-width : initial; 
border-image-outset : initial; 
border-image-repeat : initial; 
border-bottom-width : 1px; 
border-bottom-style : solid; 
border-bottom-color : rgb(0, 0, 0); 
width : 100%; 
padding : 5px 5px 5px 5px; 
} 
#i8ln1r {
width : 85%; 
} 
#iorknq {
padding : 5px 5px 5px 5px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#f2f7ff 0%, #f2f7ff 100%); 
width : 100%; 
} 
@media only screen and (max-width: 992px) {#ipjgg {
min-height : auto; 
}} 
#irztju {
color : black; 
width : 100%; 
} 
#i64bfl {
padding : 10px; 
min-height : 100px; 
width : 20%; 
} 
#i2v57k {
color : rgb(51, 51, 49); 
font-family : "Open Sans", sans-serif; 
font-size : 14px; 
background-color : rgb(246, 248, 252); 
} 
#ihov47 {
padding : 10px; 
display : block; 
font-weight : 400; 
font-size : 14px; 
font-family : 'Baloo 2', cursive; 
} 
#ilnm9v {
padding : 10px; 
min-height : 100px; 
width : 80%; 
} 
#i87ku3 {
padding : 10px; 
min-height : 100px; 
display : flex; 
} 
#i2ogx6 {
padding : 0px 0px 0px 0px; 
min-height : 100px; 
width : 50%; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#f6f8fc 0%, #f6f8fc 100%); 
} 
@media only screen and (max-width: 480px) {#i2ogx6 {
  display: block;
width : 100%; 
}} 
#i6rul9 {
color : black; 
width : 100%; 
} 
#ilk7fh {
padding : 10px; 
min-height : 100px; 
width : 20%; 
} 
#i7j3qk {
color : rgb(51, 51, 49); 
font-family : "Open Sans", sans-serif; 
font-size : 14px; 
background-color : rgb(246, 248, 252); 
} 
#iktv2h {
padding : 10px; 
display : block; 
font-weight : 400; 
font-size : 14px; 
font-family : 'Baloo 2', cursive; 
} 
#ip52s8 {
padding : 10px; 
min-height : 100px; 
width : 80%; 
} 
#i82gav {
padding : 10px; 
min-height : 100px; 
display : flex; 
} 
#io6g5h {
padding : 0px 0px 0px 0px; 
min-height : 100px; 
width : 50%; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#f6f8fc 0%, #f6f8fc 100%); 
} 
@media only screen and (max-width: 480px) {#io6g5h {
width : 100%; 
}} 
#ium1n1 {
color : black; 
width : 100%; 
} 
#i3ih67 {
padding : 10px; 
min-height : 100px; 
width : 20%; 
} 
#i2o5uq {
color : rgb(51, 51, 49); 
font-family : "Open Sans", sans-serif; 
font-size : 14px; 
background-color : rgb(246, 248, 252); 
} 
#iyz2tm {
padding : 10px; 
display : block; 
font-weight : 400; 
font-size : 14px; 
font-family : 'Baloo 2', cursive; 
} 
#inmcsn {
padding : 10px; 
min-height : 100px; 
width : 80%; 
} 
#igmds6 {
padding : 10px; 
min-height : 100px; 
display : flex; 
} 
#ivry5i {
padding : 0px 0px 0px 0px; 
min-height : 100px; 
width : 50%; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#f6f8fc 0%, #f6f8fc 100%); 
} 
@media only screen and (max-width: 480px) {#ivry5i {
width : 100%; 
}} 
#ichds6 {
padding : 0px 0px 0px 0px; 
min-height : 100px; 
display : none; 
} 
@media only screen and (max-width: 480px) {#ichds6 {
flex-wrap : wrap; 
display : block; 
}} 
@media only screen and (max-width: 480px) {#ivee {
justify-content : space-between; 
}} 
.contentwrapperanimated {
width : 50px; 
height : 50px; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
display : none; 
flex-direction : row; 
justify-content : center; 
align-content : center; 
align-items : center; 
} 
.container_animated {
display : flex; 
flex-direction : column; 
justify-content : center; 
align-content : center; 
align-items : center; 
width : 90%; 
height : 90%; 
} 
.menu__box {
display : block; 
position : fixed; 
top : 0px; 
left : -100%; 
width : 300px; 
height : 100vh; 
margin-top : 0px; 
margin-right : 0px; 
margin-bottom : 0px; 
margin-left : 0px; 
padding-top : 80px; 
padding-right : 0px; 
padding-bottom : 80px; 
padding-left : 0px; 
list-style-position : initial; 
list-style-image : initial; 
list-style-type : none; 
background-color : rgb(214, 164, 25); 
box-shadow : rgba(0, 0, 0, 0.4) 2px 2px 6px; 
transition-duration : 0.25s; 
z-index : 1; 
} 
.menu__item {
display : block; 
padding-top : 12px; 
padding-right : 24px; 
padding-bottom : 12px; 
padding-left : 24px; 
text-align : center; 
color : black; 
font-family : Roboto, sans-serif; 
font-size : 20px; 
font-weight : 600; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
transition-duration : 0.25s; 
} 
.menu__item:hover  {
background-color : rgb(207, 216, 220); 
} 
.menu__btn {
display : flex; 
flex-direction : column; 
position : absolute; 
align-items : center; 
cursor : pointer; 
width : 30px; 
height : 40px; 
z-index : 2; 
} 
.span_middle {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
} 
.span_before {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
} 
.span_after {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
} 
.menu__close__btn {
display : flex; 
flex-direction : column; 
position : absolute; 
align-items : center; 
cursor : pointer; 
width : 25px; 
height : 30px; 
left : -100%; 
z-index : 2; 
} 
.span__close__left {
display : block; 
position : relative; 
width : 100%; 
height : 2px; 
margin-top : 0px; 
margin-bottom : 0px; 
background-color : black; 
transform : rotate(45deg); 
} 
.span__close__right {
display : block; 
position : relative; 
width : 100%; 
height : 2px; 
top : 0px; 
margin-top : 0px; 
margin-bottom : 0px; 
background-color : black; 
transform : rotate(135deg); 
} 
.linkhrefs {
position : fixed; 
} 
@media only screen and (max-width: 480px) {.menu__box {
z-index : 2; 
}} 
@media only screen and (max-width: 480px) {.contentwrapperanimated {
display : block; 
}} 
@media only screen and (max-width: 480px) {.span__close__left {
z-index : 100; 
}} 
@media only screen and (max-width: 480px) {.menu__close__btn {
z-index : 10; 
}} 
#i84a68 {
display : block; 
} 
#i0r6tk {
display : block; 
} 

}
  