
#id6492527ba52ebcacc7a80fa1 {
  #ivee {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : space-around; 
align-items : center; 
} 
#i67f {
color : black; 
width : 252px; 
height : 251.67972500000002px; 
z-index : 10; 
margin : auto auto auto auto; 
float : right; 
} 
#iq9l {
padding : 10px; 
min-height : 100px; 
width : 76.79%; 
display : flex; 
align-items : center; 
justify-content : space-around; 
} 
#i5tql {
color : #d983a6; 
padding : 5px; 
display : block; 
} 
#i1n85 {
width : auto; 
color : #df9405; 
font-size : 16px; 
font-family : 'Baloo 2', cursive; 
} 
#if57z {
width : auto; 
color : #df9405; 
font-size : 16px; 
font-family : 'Baloo 2', cursive; 
} 
#ikkdy {
color : #d983a6; 
padding : 5px; 
display : block; 
} 
#iqceg {
width : auto; 
color : #df9405; 
font-size : 16px; 
font-family : 'Baloo 2', cursive; 
} 
#ifv1s {
color : #d983a6; 
padding : 5px; 
display : block; 
} 
#islmj {
padding : 10px; 
min-height : 41px; 
width : 35%; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#f2f7ff 0%, #f2f7ff 100%); 
border-radius : 12px 12px 12px 12px; 
display : flex; 
flex-direction : row; 
height : 60px; 
} 
.standard_button {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
text-align : center; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
display : inline-block; 
border-top-width : 1px; 
border-right-width : 1px; 
border-bottom-width : 1px; 
border-left-width : 1px; 
border-top-style : solid; 
border-right-style : solid; 
border-bottom-style : solid; 
border-left-style : solid; 
border-top-color : rgb(0, 119, 204); 
border-right-color : rgb(0, 119, 204); 
border-bottom-color : rgb(0, 119, 204); 
border-left-color : rgb(0, 119, 204); 
border-image-source : initial; 
border-image-slice : initial; 
border-image-width : initial; 
border-image-outset : initial; 
border-image-repeat : initial; 
font-size : 14px; 
border-top-left-radius : 5px; 
border-top-right-radius : 5px; 
border-bottom-right-radius : 5px; 
border-bottom-left-radius : 5px; 
margin-top : 4px; 
margin-right : 2px; 
margin-bottom : 4px; 
margin-left : 2px; 
cursor : pointer; 
color : rgb(255, 255, 255); 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#3f7696 0%, #3f7696 100%); 
} 
.standard_button:disabled  {
background-color : rgb(226, 229, 222); 
color : gray; 
} 
.standard_button:hover  {
background-image : linear-gradient(rgb(81, 169, 238), rgb(20, 123, 205)); 
border-top-color : rgb(20, 130, 208); 
border-right-color : rgb(20, 130, 208); 
border-bottom-color : rgb(20, 130, 208); 
border-left-color : rgb(20, 130, 208); 
color : rgb(255, 255, 255); 
} 
.standard_button:active  {
background-image : radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%); 
border-top-color : rgb(0, 109, 188); 
border-right-color : rgb(0, 109, 188); 
border-bottom-color : rgb(0, 109, 188); 
border-left-color : rgb(0, 109, 188); 
color : rgb(255, 255, 255); 
} 
#inqxd {
padding : 10px; 
width : 175px; 
min-height : 60px; 
display : flex; 
justify-content : space-around; 
} 
.gjs-container {
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
min-height : 100px; 
display : block; 
} 
#ijy2n {
color : black; 
width : 30px; 
height : auto; 
} 
#i6hs3 {
width : auto; 
min-height : 40px; 
display : block; 
padding : 5px 5px 5px 5px; 
} 
.swiper {
width : 100%; 
height : 300px; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
} 
.swiper.mySwiper {
height : 100%; 
padding : 0px 0px 0px 0px; 
__background-type : image; 
background-repeat : no-repeat; 
background-position : center center; 
background-attachment : scroll; 
background-size : cover; 
background-image : url('https://assetsprojects.s3.amazonaws.com/404p7nilj3gpcrg.jpg'); 
} 
.swiper-slide {
padding : 0px 0px 0px 0px; 
__background-type : image; 
background-repeat : no-repeat; 
background-position : center center; 
background-attachment : scroll; 
background-size : cover; 
background-image : url('https://assetsprojects.s3.amazonaws.com/404p7nilj3gpcrg.jpg'); 
} 
.href_button {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
text-align : center; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
} 
.href_button:disabled  {
background-color : rgb(226, 229, 222); 
color : gray; 
} 
.href_button:hover  {
background-image : linear-gradient(rgb(81, 169, 238), rgb(20, 123, 205)); 
border-top-color : rgb(20, 130, 208); 
border-right-color : rgb(20, 130, 208); 
border-bottom-color : rgb(20, 130, 208); 
border-left-color : rgb(20, 130, 208); 
color : rgb(255, 255, 255); 
} 
.href_button:active  {
background-image : radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%); 
border-top-color : rgb(0, 109, 188); 
border-right-color : rgb(0, 109, 188); 
border-bottom-color : rgb(0, 109, 188); 
border-left-color : rgb(0, 109, 188); 
color : rgb(255, 255, 255); 
} 
.swiper-button-next {
color : white; 
} 
.swiper-button-prev {
color : white; 
} 
#i7l1k {
padding : 10px; 
display : block; 
color : white; 
text-align : center; 
font-size : 13px; 
font-family : Lucida Sans Unicode, Lucida Grande, sans-serif; 
} 
#ig891 {
padding : 10px; 
min-height : auto; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#3f7696 0%, #3f7696 100%); 
margin : 0px 0px 0px 0px; 
} 
#ie6sa {
padding : 10px; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#f2f7ff 0%, #f2f7ff 100%); 
} 
#iqobg {
padding : 10px; 
min-height : 100px; 
} 
#ibllc {
padding : 10px; 
display : block; 
font-size : 42px; 
font-family : 'Baloo 2', cursive; 
font-weight : 700; 
text-align : center; 
color : #14569e; 
} 
#ihuk4 {
padding : 10px; 
min-height : 100px; 
display : flex; 
flex-wrap : wrap; 
} 
#iu87j {
padding : 10px; 
min-height : 100px; 
width : 50%; 
text-align : right; 
} 
#i1i8k {
color : black; 
margin : 0px 0px 0px 0px; 
z-index : 10; 
width : 75px; 
height : 75px; 
} 
#iwg4f {
padding : 10px; 
min-height : 100px; 
width : 50%; 
} 
#irgjju {
display : flex; 
align-items : center; 
padding : 0px 10px 0px 10px; 
width : 412.328125px; 
} 
#iqcul1 {
color : black; 
width : 37px; 
height : 37px; 
} 
#iqq4mu {
padding : 10px; 
display : block; 
font-family : 'Baloo 2', cursive; 
font-weight : 700; 
color : #024d67; 
font-size : 22px; 
} 
#ih8ro2 {
color : black; 
width : 37px; 
height : 37px; 
} 
#ivdrgi {
padding : 10px; 
display : block; 
font-family : 'Baloo 2', cursive; 
font-weight : 700; 
color : #024d67; 
font-size : 22px; 
} 
#i0mmmx {
display : flex; 
align-items : center; 
padding : 0px 10px 0px 10px; 
width : 419.86328125px; 
} 
#iu0rp5 {
color : black; 
width : 37px; 
height : 37px; 
} 
#iqzql2 {
padding : 10px; 
display : block; 
font-family : 'Baloo 2', cursive; 
font-weight : 700; 
color : #024d67; 
font-size : 22px; 
} 
#ii2lsw {
display : flex; 
align-items : center; 
padding : 0px 10px 0px 10px; 
width : 412.484375px; 
} 
#iae27s {
color : black; 
width : 37px; 
height : 37px; 
} 
#ikdl4l {
padding : 10px; 
display : block; 
font-family : 'Baloo 2', cursive; 
font-weight : 700; 
color : #024d67; 
font-size : 22px; 
} 
#i5vgyt {
display : flex; 
align-items : center; 
padding : 0px 10px 0px 10px; 
width : 409.12109375px; 
} 
#ipezzh {
padding : 10px; 
min-height : 100px; 
} 
#ihis35 {
min-height : 100px; 
width : 100%; 
display : flex; 
flex-direction : column; 
justify-content : flex-start; 
} 
#ipdx1n {
padding : 10px 10% 10px 10%; 
min-height : 100px; 
display : flex; 
justify-content : space-between; 
flex-wrap : wrap; 
align-items : flex-start; 
} 
#iwkkz {
padding : 10px; 
min-height : 100px; 
border : 1px solid #df9405; 
width : 656.422px; 
} 
#i87kc {
padding : 10px; 
display : block; 
font-size : 30px; 
font-family : 'Baloo 2', cursive; 
font-weight : 600; 
color : #df9405; 
text-align : center; 
} 
.formContainer {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
height : auto; 
} 
.componentInput {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
margin-bottom : 10px; 
background-image : linear-gradient(#f2f1f6 0%, #f2f1f6 100%); 
background-position-x : initial; 
background-position-y : initial; 
background-size : initial; 
background-repeat-x : initial; 
background-repeat-y : initial; 
background-attachment : initial; 
background-origin : initial; 
background-clip : initial; 
background-color : transparent; 
border-top-width : initial; 
border-right-width : initial; 
border-left-width : initial; 
border-top-style : none; 
border-right-style : none; 
border-left-style : none; 
border-top-color : initial; 
border-right-color : initial; 
border-left-color : initial; 
border-image-source : initial; 
border-image-slice : initial; 
border-image-width : initial; 
border-image-outset : initial; 
border-image-repeat : initial; 
border-bottom-width : 1px; 
border-bottom-style : solid; 
border-bottom-color : rgb(0, 0, 0); 
width : 100%; 
__background-type : color; 
border-radius : 10px 10px 10px 10px; 
border : 1px solid #e5e7ee; 
} 
.title {
display : flex; 
align-items : center; 
margin-bottom : 20px; 
} 
.fieldsContainer {
display : flex; 
flex-direction : column; 
padding-top : 20px; 
padding-right : 20px; 
padding-bottom : 20px; 
padding-left : 20px; 
} 
.formSubmitButton {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
text-align : center; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
display : inline-block; 
background-image : radial-gradient(circle at 10% 20%, rgb(226, 37, 37) 0%, rgb(211, 49, 49) 82.8%); 
border-top-width : 1px; 
border-right-width : 1px; 
border-bottom-width : 1px; 
border-left-width : 1px; 
border-top-style : solid; 
border-right-style : solid; 
border-bottom-style : solid; 
border-left-style : solid; 
border-top-color : rgb(0, 119, 204); 
border-right-color : rgb(0, 119, 204); 
border-bottom-color : rgb(0, 119, 204); 
border-left-color : rgb(0, 119, 204); 
border-image-source : initial; 
border-image-slice : initial; 
border-image-width : initial; 
border-image-outset : initial; 
border-image-repeat : initial; 
font-size : 14px; 
border-top-left-radius : 5px; 
border-top-right-radius : 5px; 
border-bottom-right-radius : 5px; 
border-bottom-left-radius : 5px; 
margin-top : 4px; 
margin-right : 2px; 
margin-bottom : 4px; 
margin-left : 2px; 
cursor : pointer; 
color : rgb(255, 255, 255); 
width : 204.4531px; 
} 
.formSubmitButton:disabled  {
background-color : rgb(226, 229, 222); 
color : gray; 
} 
.formSubmitButton:hover  {
background-image : linear-gradient(rgb(81, 169, 238), rgb(20, 123, 205)); 
border-top-color : rgb(20, 130, 208); 
border-right-color : rgb(20, 130, 208); 
border-bottom-color : rgb(20, 130, 208); 
border-left-color : rgb(20, 130, 208); 
color : rgb(255, 255, 255); 
} 
.formSubmitButton:active  {
background-image : radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%); 
border-top-color : rgb(0, 109, 188); 
border-right-color : rgb(0, 109, 188); 
border-bottom-color : rgb(0, 109, 188); 
border-left-color : rgb(0, 109, 188); 
color : rgb(255, 255, 255); 
} 
#ihgrx {
padding : 10px; 
min-height : 100px; 
display : flex; 
flex-wrap : wrap; 
} 
#iqhzx {
color : #14569e; 
font-size : 14px; 
font-family : 'Baloo 2', cursive; 
font-weight : 600; 
} 
#i9ophm {
padding : 10px; 
min-height : 100px; 
width : 50%; 
} 
#i9nibi {
color : #14569e; 
font-size : 14px; 
font-family : 'Baloo 2', cursive; 
font-weight : 600; 
} 
#in8gvh {
padding : 10px; 
min-height : 100px; 
width : 50%; 
} 
#i4xbrj {
padding : 10px; 
min-height : 100px; 
display : flex; 
flex-wrap : wrap; 
} 
#i1abtw {
color : #14569e; 
font-size : 14px; 
font-family : 'Baloo 2', cursive; 
font-weight : 600; 
} 
#i64jah {
padding : 10px; 
min-height : 100px; 
width : 50%; 
} 
#iso3hk {
color : #14569e; 
font-size : 14px; 
font-family : 'Baloo 2', cursive; 
font-weight : 600; 
} 
#i9u9ro {
padding : 10px; 
min-height : 100px; 
width : 50%; 
} 
#ii2bzq {
padding : 10px; 
min-height : 100px; 
display : flex; 
flex-wrap : wrap; 
} 
#icco8t {
padding : 10px; 
display : block; 
font-size : 30px; 
font-family : Helvetica, sans-serif; 
font-weight : 600; 
color : #df9405; 
text-align : center; 
} 
#iud3s7 {
padding : 10px; 
border : 1px solid #df9405; 
margin : 0px 0px 0px 0px; 
width : 477.141px; 
} 
#inhuay {
padding : 10px; 
display : flex; 
flex-direction : row-reverse; 
height : 83px; 
align-items : center; 
} 
#i0ddst {
padding : 10px 10% 10px 10%; 
display : block; 
text-align : center; 
font-family : 'Baloo 2', cursive; 
font-size : 17px; 
font-weight : 500; 
color : #565656; 
margin : 0 0 75px 0; 
} 
#ibucm6 {
color : #14569e; 
font-size : 14px; 
font-family : 'Baloo 2', cursive; 
font-weight : 600; 
} 
#iwagqc {
padding : 10px; 
min-height : 100px; 
} 
#irfkh {
padding : 5px 5px 5px 5px; 
color : white; 
border-radius : 10px 10px 10px 10px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#3f7696 0%, #3f7696 100%); 
} 
#ivb27 {
font-family : 'Baloo 2', cursive; 
} 
.inputContainer {
width : 100px; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
} 
.formInput {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
margin-bottom : 10px; 
background-image : initial; 
background-position-x : initial; 
background-position-y : initial; 
background-size : initial; 
background-repeat-x : initial; 
background-repeat-y : initial; 
background-attachment : initial; 
background-origin : initial; 
background-clip : initial; 
background-color : transparent; 
border-top-width : initial; 
border-right-width : initial; 
border-left-width : initial; 
border-top-style : none; 
border-right-style : none; 
border-left-style : none; 
border-top-color : initial; 
border-right-color : initial; 
border-left-color : initial; 
border-image-source : initial; 
border-image-slice : initial; 
border-image-width : initial; 
border-image-outset : initial; 
border-image-repeat : initial; 
border-bottom-width : 1px; 
border-bottom-style : solid; 
border-bottom-color : rgb(0, 0, 0); 
width : 100%; 
} 
#i42p08 {
padding : 5px 5px 5px 5px; 
width : 100%; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#f2f7ff 0%, #f2f7ff 100%); 
} 
#irz9ej {
width : 85%; 
} 
#iq990n {
padding : 10px; 
display : block; 
font-size : 30px; 
font-family : Helvetica, sans-serif; 
font-weight : 600; 
color : #df9405; 
text-align : center; 
} 
#i9vgjp {
color : #14569e; 
font-size : 14px; 
font-family : 'Baloo 2', cursive; 
font-weight : 600; 
} 
#ivd9n9 {
padding : 10px; 
min-height : 100px; 
width : 25.16%; 
} 
#iq3ifc {
color : #14569e; 
font-size : 14px; 
font-family : 'Baloo 2', cursive; 
font-weight : 600; 
} 
#iq0ga3 {
padding : 10px; 
min-height : 100px; 
width : 25.16%; 
} 
#iiu0oq {
color : #14569e; 
font-size : 14px; 
font-family : 'Baloo 2', cursive; 
font-weight : 600; 
} 
#iix18y {
padding : 10px; 
min-height : 100px; 
width : 43.88%; 
} 
#iqweg8 {
padding : 10px; 
min-height : 100px; 
display : flex; 
flex-wrap : wrap; 
} 
#igohcl {
color : #14569e; 
font-size : 14px; 
font-family : 'Baloo 2', cursive; 
font-weight : 600; 
} 
#iuyegd {
padding : 10px; 
min-height : 100px; 
width : 50%; 
} 
#ipq6dx {
color : #14569e; 
font-size : 14px; 
font-family : 'Baloo 2', cursive; 
font-weight : 600; 
} 
#i6jeuz {
padding : 10px; 
min-height : 100px; 
width : 50%; 
} 
#ils4ts {
padding : 10px; 
min-height : 100px; 
display : flex; 
width : 97.44%; 
flex-wrap : wrap; 
} 
#i6irzp {
height : 21px; 
font-family : 'Baloo 2', cursive; 
} 
#i887bi {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#df9405 0%, #df9405 100%); 
border : 1px solid white; 
width : 212.2812px; 
} 
#ig8syl {
padding : 10px; 
display : flex; 
flex-direction : row-reverse; 
height : 83px; 
align-items : center; 
} 
#ib241h {
padding : 10px; 
min-height : 100px; 
border : 1px solid #df9405; 
margin : 30px 0px 0px 0px; 
display : none; 
} 
#ig0q0j {
padding : 10px; 
min-height : 100px; 
} 
#iro57v {
padding : 10px; 
display : block; 
width : 100px; 
font-weight : 600; 
} 
#iosfog {
padding : 10px; 
display : flex; 
font-family : 'Baloo 2', cursive; 
} 
#ig3dpu {
padding : 10px; 
display : block; 
} 
#ifzmmq {
padding : 10px; 
display : block; 
width : 100px; 
font-weight : 600; 
} 
#icyaco {
padding : 10px; 
display : block; 
} 
#i5me7j {
padding : 10px; 
display : flex; 
font-family : 'Baloo 2', cursive; 
} 
#itbho8 {
padding : 10px; 
display : block; 
width : 100px; 
font-weight : 600; 
} 
#i6maui {
padding : 10px; 
display : block; 
} 
#ie1c0n {
padding : 10px; 
display : flex; 
font-family : 'Baloo 2', cursive; 
} 
.paypal_button {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
text-align : center; 
background-color : rgb(255, 196, 57); 
border-top-left-radius : 4px; 
border-top-right-radius : 4px; 
border-bottom-right-radius : 4px; 
border-bottom-left-radius : 4px; 
cursor : pointer; 
width : 200px; 
display : flex; 
flex-direction : row; 
justify-content : center; 
align-items : center; 
} 
.paypal_button:disabled  {
background-color : rgb(226, 229, 222); 
color : gray; 
} 
.paypal_button:hover  {
background-color : rgb(226, 229, 222); 
border-top-color : rgb(20, 130, 208); 
border-right-color : rgb(20, 130, 208); 
border-bottom-color : rgb(20, 130, 208); 
border-left-color : rgb(20, 130, 208); 
color : rgb(255, 255, 255); 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#b79c41 0%, #b79c41 100%); 
} 
.paypal_button:active  {
background-image : radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%); 
border-top-color : rgb(0, 109, 188); 
border-right-color : rgb(0, 109, 188); 
border-bottom-color : rgb(0, 109, 188); 
border-left-color : rgb(0, 109, 188); 
color : rgb(255, 255, 255); 
} 
#idmrdf {
width : 40px; 
height : 40px; 
} 
#imjdjr {
display : block; 
} 
.contentwrapperanimated {
width : 50px; 
height : 50px; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
display : none; 
flex-direction : row; 
justify-content : center; 
align-content : center; 
align-items : center; 
} 
.container_animated {
display : flex; 
flex-direction : column; 
justify-content : center; 
align-content : center; 
align-items : center; 
width : 90%; 
height : 90%; 
} 
.menu__box {
display : block; 
position : fixed; 
top : 0px; 
left : -100%; 
width : 300px; 
height : 100vh; 
margin-top : 0px; 
margin-right : 0px; 
margin-bottom : 0px; 
margin-left : 0px; 
padding-top : 80px; 
padding-right : 0px; 
padding-bottom : 80px; 
padding-left : 0px; 
list-style-position : initial; 
list-style-image : initial; 
list-style-type : none; 
background-color : rgb(214, 164, 25); 
box-shadow : rgba(0, 0, 0, 0.4) 2px 2px 6px; 
transition-duration : 0.25s; 
z-index : 1; 
} 
.menu__item {
display : block; 
padding-top : 12px; 
padding-right : 24px; 
padding-bottom : 12px; 
padding-left : 24px; 
text-align : center; 
color : black; 
font-family : Roboto, sans-serif; 
font-size : 20px; 
font-weight : 600; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
transition-duration : 0.25s; 
} 
.menu__item:hover  {
background-color : rgb(207, 216, 220); 
} 
.menu__btn {
display : flex; 
flex-direction : column; 
position : absolute; 
align-items : center; 
cursor : pointer; 
width : 30px; 
height : 40px; 
z-index : 2; 
} 
.span_middle {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
} 
.span_before {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
} 
.span_after {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
} 
.menu__close__btn {
display : flex; 
flex-direction : column; 
position : absolute; 
align-items : center; 
cursor : pointer; 
width : 25px; 
height : 30px; 
left : -100%; 
z-index : 2; 
} 
.span__close__left {
display : block; 
position : relative; 
width : 100%; 
height : 2px; 
margin-top : 0px; 
margin-bottom : 0px; 
background-color : black; 
transform : rotate(45deg); 
} 
.span__close__right {
display : block; 
position : relative; 
width : 100%; 
height : 2px; 
top : 0px; 
margin-top : 0px; 
margin-bottom : 0px; 
background-color : black; 
transform : rotate(135deg); 
} 
.linkhrefs {
position : fixed; 
} 
#iyge5i {
color : #14569e; 
font-size : 14px; 
font-family : 'Baloo 2', cursive; 
font-weight : 600; 
} 
#ijl2w3 {
padding : 10px; 
min-height : 100px; 
width : 50%; 
} 
#itqo46 {
padding : 10px; 
min-height : 100px; 
} 
#i4laia {
padding : 10px; 
display : block; 
font-size : 30px; 
font-family : 'Baloo 2', cursive; 
font-weight : 600; 
color : #df9405; 
text-align : center; 
} 
#i2m4cl {
color : #14569e; 
font-size : 14px; 
font-family : 'Baloo 2', cursive; 
font-weight : 600; 
} 
#i0alnf {
padding : 10px; 
min-height : 100px; 
width : 50%; 
} 
#ingxgi {
padding : 10px; 
min-height : 100px; 
display : flex; 
flex-wrap : wrap; 
} 
#i7pu76 {
color : #14569e; 
font-size : 14px; 
font-family : 'Baloo 2', cursive; 
font-weight : 600; 
} 
#iwmjbi {
padding : 10px; 
min-height : 100px; 
} 
#iojguy {
padding : 10px; 
min-height : 100px; 
display : flex; 
flex-wrap : wrap; 
} 
#i0k2rf {
padding : 10px; 
min-height : 100px; 
} 
#itmg5q {
display : flex; 
} 
#i0l6ep {
padding : 10px; 
min-height : 100px; 
width : 50%; 
display : flex; 
align-items : flex-start; 
justify-content : center; 
flex-wrap : wrap; 
} 
#ia81uk {
color : black; 
width : 100%; 
} 
#i3f3ph {
color : black; 
width : 100%; 
} 
#i56g2a {
padding : 10px; 
width : 50%; 
} 
#ifljm2 {
padding : 10px; 
min-height : 100px; 
display : flex; 
flex-wrap : wrap; 
} 
.checkboxMain {
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
} 
.checkboxContainer {
display : block; 
position : relative; 
padding-left : 35px; 
margin-bottom : 12px; 
cursor : pointer; 
user-select : none; 
} 
.formCheckbox {
position : absolute; 
opacity : 0; 
cursor : pointer; 
height : 0px; 
width : 0px; 
} 
.checkmark {
position : absolute; 
top : 0px; 
left : 0px; 
height : 25px; 
width : 25px; 
background-color : rgb(238, 238, 238); 
} 
.checkboxContainer:hover input ~ .checkmark {
background-color : rgb(204, 204, 204); 
} 
.checkboxContainer input:checked ~ .checkmark {
background-color : rgb(33, 150, 243); 
} 
#ii1eoh {
color : #14569e; 
font-family : 'Baloo 2', cursive; 
font-weight : 600; 
} 
#i7p9wf {
padding : 10px; 
display : block; 
width : 100px; 
font-weight : 600; 
} 
#ivqq0x {
padding : 10px; 
display : block; 
} 
#i5697l {
padding : 10px; 
display : flex; 
font-family : 'Baloo 2', cursive; 
} 
#i83nbl {
padding : 10px; 
min-height : 100px; 
} 
#i8p5dy {
padding : 10px; 
display : block; 
font-size : 30px; 
font-family : Helvetica, sans-serif; 
font-weight : 600; 
color : #df9405; 
text-align : center; 
} 
#i170zs {
padding : 10px; 
min-height : 100px; 
} 
#ivaiyo {
width : auto; 
color : #df9405; 
font-size : 16px; 
font-family : 'Baloo 2', cursive; 
} 
#iotp69 {
color : #d983a6; 
padding : 5px; 
display : block; 
} 

  @media only screen and (max-width: 992px) {#i1i8k {
width : auto; 
height : 75px; 
}} 
@media only screen and (max-width: 992px) {#inqxd {
width : 106.757375px; 
}} 
@media only screen and (max-width: 992px) {#ibllc {
font-size : 22px; 
}} 
@media only screen and (max-width: 992px) {#iqq4mu {
font-size : 16px; 
}} 
@media only screen and (max-width: 992px) {#ikdl4l {
font-size : 16px; 
}} 
@media only screen and (max-width: 992px) {#iqzql2 {
font-size : 16px; 
}} 
@media only screen and (max-width: 992px) {#ivdrgi {
font-size : 16px; 
}} 
@media only screen and (max-width: 992px) {#i0mmmx {
width : 329.155875px; 
}} 
@media only screen and (max-width: 992px) {#i67f {
width : 177.265625px; 
height : 177px; 
}} 
@media only screen and (max-width: 992px) {#ils4ts {
width : 520px; 
}} 
@media only screen and (max-width: 992px) {#iud3s7 {
margin : 30px 0px 0px 0px; 
width : 100%; 
}} 

  @media only screen and (max-width: 480px) {#iu87j {
width : 100%; 
text-align : center; 
display : flex; 
}} 
@media only screen and (max-width: 480px) {#iq9l {
display : none; 
}} 
@media only screen and (max-width: 480px) {#i1i8k {
width : 101px; 
height : 101.29340625px; 
}} 
@media only screen and (max-width: 480px) {#i67f {
float : center; 
text-align : center; 
}} 
@media only screen and (max-width: 480px) {#iwg4f {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#iqq4mu {
font-size : 10px; 
}} 
@media only screen and (max-width: 480px) {#ikdl4l {
font-size : 10px; 
}} 
@media only screen and (max-width: 480px) {#iqzql2 {
font-size : 10px; 
}} 
@media only screen and (max-width: 480px) {#ivdrgi {
font-size : 10px; 
}} 
@media only screen and (max-width: 480px) {#i0mmmx {
width : 233.14018750000002px; 
}} 
@media only screen and (max-width: 480px) {#ii2lsw {
width : 170.71446875px; 
}} 
@media only screen and (max-width: 480px) {#i5vgyt {
width : 150.11309375px; 
}} 
@media only screen and (max-width: 480px) {#irgjju {
width : 141.49596875px; 
}} 
@media only screen and (max-width: 480px) {#i9ophm {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i64jah {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i9u9ro {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#in8gvh {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#icco8t {
font-size : 20px; 
}} 
@media only screen and (max-width: 480px) {#i87kc {
font-size : 20px; 
}} 
@media only screen and (max-width: 480px) {#ipezzh {
min-height : 67px; 
}} 
@media only screen and (max-width: 480px) {#ipdx1n {
padding : 10px 10px 10px 10px; 
}} 
@media only screen and (max-width: 480px) {#i0ddst {
margin : 0px 0px 30px 0px; 
}} 
@media only screen and (max-width: 480px) {#iwagqc {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#iq990n {
font-size : 20px; 
}} 
@media only screen and (max-width: 480px) {#ivd9n9 {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#iq0ga3 {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#iix18y {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#iuyegd {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i6jeuz {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#ils4ts {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#ivee {
justify-content : space-between; 
}} 
@media only screen and (max-width: 480px) {.contentwrapperanimated {
display : block; 
}} 
@media only screen and (max-width: 480px) {#ijl2w3 {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i4laia {
font-size : 20px; 
}} 
@media only screen and (max-width: 480px) {#i0alnf {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#iwmjbi {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i56g2a {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i8p5dy {
font-size : 20px; 
}} 
@media only screen and (max-width: 480px) {.fieldsContainer {
padding : 20px 0px 20px 0px; 
}} 
@media only screen and (max-width: 480px) {#i0l6ep {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#itmg5q {
flex-wrap : wrap; 
}} 

}
  