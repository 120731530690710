
#id64eebf329255464525648cf0 {
  #iklu {
padding : 10px; 
min-height : 100px; 
height : 100vh; 
display : flex; 
justify-content : space-around; 
align-items : center; 
} 
#igf2 {
padding : 10px; 
min-height : 100px; 
width : 75%; 
} 
#id3m {
padding : 10px; 
display : block; 
font-family : 'Baloo 2', cursive; 
font-size : 64px; 
font-weight : 800; 
text-align : center; 
} 
#i1fj {
color : black; 
width : 225px; 
height : 225.29999999999995px; 
} 
#ipea {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : space-around; 
} 
#i4zog {
padding : 10px; 
display : flex; 
justify-content : space-around; 
} 
.href_button {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
text-align : center; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
} 
.href_button:disabled  {
background-color : rgb(226, 229, 222); 
color : gray; 
} 
.href_button:hover  {
background-image : linear-gradient(rgb(81, 169, 238), rgb(20, 123, 205)); 
border-top-color : rgb(20, 130, 208); 
border-right-color : rgb(20, 130, 208); 
border-bottom-color : rgb(20, 130, 208); 
border-left-color : rgb(20, 130, 208); 
color : rgb(255, 255, 255); 
} 
.href_button:active  {
background-image : radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%); 
border-top-color : rgb(0, 109, 188); 
border-right-color : rgb(0, 109, 188); 
border-bottom-color : rgb(0, 109, 188); 
border-left-color : rgb(0, 109, 188); 
color : rgb(255, 255, 255); 
} 
#i867o {
padding : 5px 5px; 
text-align : center; 
display : inline-block; 
min-width : 50px; 
min-height : 30px; 
background-image : linear-gradient(#3f7696 0%, #3f7696 100%); 
cursor : pointer; 
color : #fff; 
__background-type : color; 
border-radius : 10px 10px 10px 10px; 
} 

  
  @media only screen and (max-width: 480px) {#i1fj {
width : 132px; 
height : auto; 
}} 
@media only screen and (max-width: 480px) {#id3m {
font-size : 32px; 
}} 

}
  