
#id64d2852092554645256462d3 {
  #ivee {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : space-around; 
align-items : center; 
} 
#i67f {
color : black; 
width : 252px; 
height : 251.67972500000002px; 
z-index : 10; 
margin : auto auto auto auto; 
float : right; 
} 
#iq9l {
padding : 10px; 
min-height : 100px; 
width : 76.79%; 
display : flex; 
align-items : center; 
justify-content : space-around; 
} 
#i5tql {
color : #d983a6; 
padding : 5px; 
display : block; 
} 
#i1n85 {
width : auto; 
color : #df9405; 
font-size : 16px; 
font-family : 'Baloo 2', cursive; 
} 
#if57z {
width : auto; 
color : #df9405; 
font-size : 16px; 
font-family : 'Baloo 2', cursive; 
} 
#ikkdy {
color : #d983a6; 
padding : 5px; 
display : block; 
} 
#iqceg {
width : auto; 
color : #df9405; 
font-size : 16px; 
font-family : 'Baloo 2', cursive; 
} 
#ifv1s {
color : #d983a6; 
padding : 5px; 
display : block; 
} 
#islmj {
padding : 10px; 
min-height : 41px; 
width : 35%; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#f2f7ff 0%, #f2f7ff 100%); 
border-radius : 12px 12px 12px 12px; 
display : flex; 
flex-direction : row; 
height : 60px; 
} 
.standard_button {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
text-align : center; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
display : inline-block; 
border-top-width : 1px; 
border-right-width : 1px; 
border-bottom-width : 1px; 
border-left-width : 1px; 
border-top-style : solid; 
border-right-style : solid; 
border-bottom-style : solid; 
border-left-style : solid; 
border-top-color : rgb(0, 119, 204); 
border-right-color : rgb(0, 119, 204); 
border-bottom-color : rgb(0, 119, 204); 
border-left-color : rgb(0, 119, 204); 
border-image-source : initial; 
border-image-slice : initial; 
border-image-width : initial; 
border-image-outset : initial; 
border-image-repeat : initial; 
font-size : 14px; 
border-top-left-radius : 5px; 
border-top-right-radius : 5px; 
border-bottom-right-radius : 5px; 
border-bottom-left-radius : 5px; 
margin-top : 4px; 
margin-right : 2px; 
margin-bottom : 4px; 
margin-left : 2px; 
cursor : pointer; 
color : rgb(255, 255, 255); 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#3f7696 0%, #3f7696 100%); 
} 
.standard_button:disabled  {
background-color : rgb(226, 229, 222); 
color : gray; 
} 
.standard_button:hover  {
background-image : linear-gradient(rgb(81, 169, 238), rgb(20, 123, 205)); 
border-top-color : rgb(20, 130, 208); 
border-right-color : rgb(20, 130, 208); 
border-bottom-color : rgb(20, 130, 208); 
border-left-color : rgb(20, 130, 208); 
color : rgb(255, 255, 255); 
} 
.standard_button:active  {
background-image : radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%); 
border-top-color : rgb(0, 109, 188); 
border-right-color : rgb(0, 109, 188); 
border-bottom-color : rgb(0, 109, 188); 
border-left-color : rgb(0, 109, 188); 
color : rgb(255, 255, 255); 
} 
#inqxd {
padding : 10px; 
width : 175px; 
min-height : 60px; 
display : flex; 
justify-content : space-around; 
} 
.gjs-container {
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
min-height : 100px; 
display : block; 
} 
#ijy2n {
color : black; 
width : 30px; 
height : auto; 
} 
#i6hs3 {
width : auto; 
min-height : 40px; 
display : block; 
padding : 5px 5px 5px 5px; 
} 
.swiper {
width : 100%; 
height : 300px; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
} 
.swiper.mySwiper {
height : 100%; 
padding : 0px 0px 0px 0px; 
__background-type : image; 
background-repeat : no-repeat; 
background-position : center center; 
background-attachment : scroll; 
background-size : cover; 
background-image : url('https://assetsprojects.s3.amazonaws.com/404p7nilj3gpcrg.jpg'); 
} 
.swiper-slide {
padding : 0px 0px 0px 0px; 
__background-type : image; 
background-repeat : no-repeat; 
background-position : center center; 
background-attachment : scroll; 
background-size : cover; 
background-image : url('https://assetsprojects.s3.amazonaws.com/404p7nilj3gpcrg.jpg'); 
} 
.href_button {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
text-align : center; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
} 
.href_button:disabled  {
background-color : rgb(226, 229, 222); 
color : gray; 
} 
.href_button:hover  {
background-image : linear-gradient(rgb(81, 169, 238), rgb(20, 123, 205)); 
border-top-color : rgb(20, 130, 208); 
border-right-color : rgb(20, 130, 208); 
border-bottom-color : rgb(20, 130, 208); 
border-left-color : rgb(20, 130, 208); 
color : rgb(255, 255, 255); 
} 
.href_button:active  {
background-image : radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%); 
border-top-color : rgb(0, 109, 188); 
border-right-color : rgb(0, 109, 188); 
border-bottom-color : rgb(0, 109, 188); 
border-left-color : rgb(0, 109, 188); 
color : rgb(255, 255, 255); 
} 
.swiper-button-next {
color : white; 
} 
.swiper-button-prev {
color : white; 
} 
#i7l1k {
padding : 10px; 
display : block; 
color : white; 
text-align : center; 
font-size : 13px; 
font-family : 'Baloo 2', cursive; 
} 
#ig891 {
padding : 10px; 
min-height : auto; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#3f7696 0%, #3f7696 100%); 
margin : 0px 0px 0px 0px; 
} 
#ie6sa {
padding : 10px; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#f2f7ff 0%, #f2f7ff 100%); 
} 
#iqobg {
padding : 10px; 
min-height : 100px; 
} 
#ibllc {
padding : 10px; 
display : block; 
font-size : 42px; 
font-family : 'Baloo 2', cursive; 
font-weight : 700; 
text-align : center; 
color : #14569e; 
} 
#ihuk4 {
padding : 10px; 
min-height : 100px; 
display : flex; 
flex-wrap : wrap; 
} 
#iu87j {
padding : 10px; 
min-height : 100px; 
width : 50%; 
text-align : right; 
} 
#i1i8k {
color : black; 
width : 75px; 
height : auto; 
margin : 0px 0px 0px 0px; 
z-index : 10; 
} 
#iwg4f {
padding : 10px; 
min-height : 100px; 
width : 50%; 
} 
#irgjju {
display : flex; 
align-items : center; 
padding : 0px 10px 0px 10px; 
width : 432.328125px; 
} 
#iqcul1 {
color : black; 
width : 37px; 
height : 37px; 
} 
#iqq4mu {
padding : 10px; 
display : block; 
font-family : 'Baloo 2', cursive; 
font-weight : 700; 
color : #024d67; 
font-size : 22px; 
} 
#ih8ro2 {
color : black; 
width : 37px; 
height : 37px; 
} 
#ivdrgi {
padding : 10px; 
display : block; 
font-family : 'Baloo 2', cursive; 
font-weight : 700; 
color : #024d67; 
font-size : 22px; 
} 
#i0mmmx {
display : flex; 
align-items : center; 
padding : 0px 10px 0px 10px; 
width : 431.86328125px; 
} 
#iu0rp5 {
color : black; 
width : 37px; 
height : 37px; 
} 
#iqzql2 {
padding : 10px; 
display : block; 
font-family : 'Baloo 2', cursive; 
font-weight : 700; 
color : #024d67; 
font-size : 22px; 
} 
#ii2lsw {
display : flex; 
align-items : center; 
padding : 0px 10px 0px 10px; 
width : 428.484375px; 
} 
#iae27s {
color : black; 
width : 37px; 
height : 37px; 
} 
#ikdl4l {
padding : 10px; 
display : block; 
font-family : 'Baloo 2', cursive; 
font-weight : 700; 
color : #024d67; 
font-size : 22px; 
} 
#i5vgyt {
display : flex; 
align-items : center; 
padding : 0px 10px 0px 10px; 
width : 431.12109375px; 
} 
#ihis35 {
min-height : 100px; 
width : 100%; 
display : flex; 
flex-direction : row; 
justify-content : center; 
align-items : center; 
} 
#ipdx1n {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : space-around; 
} 
.inputContainer {
width : 100%; 
border : 0px solid #d9d9d9; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#d9d9d9 0%, #d9d9d9 100%); 
top : auto; 
padding : 0px 0px 0px 0px; 
border-radius : 5px 5px 5px 5px; 
height : 163.6px; 
} 
.formInput {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
background-image : initial; 
background-position-x : initial; 
background-position-y : initial; 
background-size : initial; 
background-repeat-x : initial; 
background-repeat-y : initial; 
background-attachment : initial; 
background-origin : initial; 
background-clip : initial; 
background-color : transparent; 
border-top-width : initial; 
border-right-width : initial; 
border-left-width : initial; 
border-top-style : none; 
border-right-style : none; 
border-left-style : none; 
border-top-color : initial; 
border-right-color : initial; 
border-left-color : initial; 
border-image-source : initial; 
border-image-slice : initial; 
border-image-width : initial; 
border-image-outset : initial; 
border-image-repeat : initial; 
border-bottom-width : 1px; 
border-bottom-style : solid; 
border-bottom-color : rgb(0, 0, 0); 
width : 100%; 
border : 3px solid #d9d9d9; 
margin : 0 0 0px 0; 
align-items : center; 
border-radius : 5px 5px 5px 5px; 
} 
.gjs-row {
display : flex; 
justify-content : flex-start; 
align-items : stretch; 
flex-wrap : wrap; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
} 
.gjs-cell {
min-height : 75px; 
width : 50%; 
} 
.checkboxMain {
width : 253.5999755859375px; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
height : 47.199951171875px; 
} 
.checkboxContainer {
display : block; 
position : relative; 
padding-left : 35px; 
margin-bottom : 12px; 
cursor : pointer; 
user-select : none; 
} 
.formCheckbox {
position : absolute; 
opacity : 0; 
cursor : pointer; 
height : 0px; 
width : 0px; 
} 
.checkmark {
position : absolute; 
top : 0px; 
left : 0px; 
height : 25px; 
width : 25px; 
background-color : rgb(238, 238, 238); 
border-radius : 5px 5px 5px 5px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
border : 2px solid #1791fe; 
} 
.checkboxContainer:hover input ~ .checkmark {
background-color : rgb(204, 204, 204); 
} 
.checkboxContainer input:checked ~ .checkmark {
background-color : rgb(33, 150, 243); 
} 
#irtlk {
padding : 5px 5px 5px 5px; 
color : white; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#3f7696 0%, #3f7696 100%); 
border-radius : 10px 10px 10px 10px; 
} 
#i4lrf {
padding : 86px 10px 37px 10px; 
min-height : 100px; 
__background-type : image; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : url('https://assetsprojects.s3.amazonaws.com/405opfill2jjppa.png'); 
border-radius : 5px 5px 0px 0px; 
display : flex; 
width : 1196.15px; 
} 
#iw78x {
padding : 10px; 
min-height : 100px; 
width : 95%; 
} 
.crudContainerDB {
width : 100%; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
} 
#i42bof {
color : black; 
width : 100%; 
height : 100%; 
border-radius : 100% 100% 100% 100%; 
border : 6px solid #df5829; 
} 
#i4aj1m {
padding : 10px; 
width : 276px; 
height : 276px; 
border : 0px solid #df5829; 
position : relative; 
top : 4%; 
} 
#iuzhxd {
padding : 10px; 
display : block; 
color : #14569e; 
font-weight : 700; 
font-family : 'Baloo 2', cursive; 
font-size : 50px; 
} 
#ig6t8i {
padding : 5px 5px 5px 5px; 
color : white; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#3f7696 0%, #3f7696 100%); 
border-radius : 10px 10px 10px 10px; 
} 
#issu69 {
padding : 10px; 
min-height : 41px; 
width : 35%; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#f2f7ff 0%, #f2f7ff 100%); 
border-radius : 12px 12px 12px 12px; 
display : none; 
flex-direction : row-reverse; 
height : 60px; 
} 
#iigz33 {
padding : 10px; 
min-height : 100px; 
} 
#ikwmrv {
padding : 10px; 
min-height : 100px; 
width : 763.2000122070312px; 
} 
#ijetx2 {
padding : 10px; 
min-height : 100px; 
width : 132.7999267578125px; 
} 
#i8subx {
padding : 10px; 
min-height : 100px; 
width : 636.7999755859375px; 
border : 5px solid #df9405; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
border-radius : 10px 10px 10px 10px; 
display : flex; 
justify-content : center; 
} 
.formTextarea {
margin-top : 5px; 
margin-right : 5px; 
margin-bottom : 5px; 
margin-left : 5px; 
background-image : initial; 
background-position-x : initial; 
background-position-y : initial; 
background-size : initial; 
background-repeat-x : initial; 
background-repeat-y : initial; 
background-attachment : initial; 
background-origin : initial; 
background-clip : initial; 
background-color : transparent; 
width : 97.49%; 
height : 113.2%; 
border-top-width : initial; 
border-right-width : initial; 
border-bottom-width : initial; 
border-left-width : initial; 
border-top-style : solid; 
border-right-style : solid; 
border-bottom-style : solid; 
border-left-style : solid; 
border-top-color : black; 
border-right-color : black; 
border-bottom-color : black; 
border-left-color : black; 
border-image-source : initial; 
border-image-slice : initial; 
border-image-width : initial; 
border-image-outset : initial; 
border-image-repeat : initial; 
border : 0px solid black; 
border-radius : 0px 0px 0px 0px; 
} 
#ias3a9 {
min-height : 100px; 
padding : 10px; 
width : 465.2000244140625px; 
} 
#iv7ey4 {
padding : 10px; 
display : flex; 
flex-direction : column; 
justify-content : flex-start; 
align-items : stretch; 
} 
#i4kcb1 {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
#i2jwwr {
background-color : transparent; 
width : 100%; 
height : 154.39999389648438px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#e5e7ee 0%, #e5e7ee 100%); 
border-radius : 10px 10px 10px 10px; 
} 
#i54yl1 {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
#i0tzwh {
background-color : transparent; 
width : 100%; 
height : 146.40005493164062px; 
border-radius : 10px 10px 10px 10px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#e5e7ee 0%, #e5e7ee 100%); 
} 
#icy7gf {
text-align : center; 
width : 150px; 
float : right; 
position : relative; 
font-family : 'Baloo 2', cursive; 
font-size : 19px; 
font-weight : 700; 
padding : 5px 0px 5px 0px; 
color : white; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#df9405 0%, #df9405 100%); 
border-radius : 5px 5px 5px 5px; 
} 
#i5tpna {
padding : 10px; 
} 
#ip6hhl {
font-family : 'Baloo 2', cursive; 
color : #14569e; 
font-weight : 900; 
font-size : 1.2rem; 
} 
#iax1n6 {
font-family : 'Baloo 2', cursive; 
font-size : 1.2rem; 
font-weight : 900; 
color : #14569e; 
} 
#i7kjyf {
padding : 10px; 
min-height : 100px; 
display : flex; 
flex-direction : column; 
align-items : center; 
} 
#ij0j5r {
padding : 10px; 
display : block; 
font-family : 'Baloo 2', cursive; 
font-size : 2rem; 
font-weight : 700; 
color : #df9405; 
} 
#i9i7hn {
padding : 10px; 
display : block; 
font-family : 'Baloo 2', cursive; 
color : #14569e; 
} 
#ivb27 {
font-family : 'Baloo 2', cursive; 
} 
#i6q516 {
width : 100%; 
padding : 5px 5px 5px 5px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#f2f7ff 0%, #f2f7ff 100%); 
} 
#iafayo {
width : 85%; 
} 
#i8yvab {
display : block; 
} 
.contentwrapperanimated {
width : 50px; 
height : 50px; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
display : none; 
flex-direction : row; 
justify-content : center; 
align-content : center; 
align-items : center; 
} 
.container_animated {
display : flex; 
flex-direction : column; 
justify-content : center; 
align-content : center; 
align-items : center; 
width : 90%; 
height : 90%; 
} 
.menu__box {
display : block; 
position : fixed; 
top : 0px; 
left : -100%; 
width : 300px; 
height : 100vh; 
margin-top : 0px; 
margin-right : 0px; 
margin-bottom : 0px; 
margin-left : 0px; 
padding-top : 80px; 
padding-right : 0px; 
padding-bottom : 80px; 
padding-left : 0px; 
list-style-position : initial; 
list-style-image : initial; 
list-style-type : none; 
background-color : rgb(214, 164, 25); 
box-shadow : rgba(0, 0, 0, 0.4) 2px 2px 6px; 
transition-duration : 0.25s; 
z-index : 1; 
} 
.menu__item {
display : block; 
padding-top : 12px; 
padding-right : 24px; 
padding-bottom : 12px; 
padding-left : 24px; 
text-align : center; 
color : black; 
font-family : Roboto, sans-serif; 
font-size : 20px; 
font-weight : 600; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
transition-duration : 0.25s; 
} 
.menu__item:hover  {
background-color : rgb(207, 216, 220); 
} 
.menu__btn {
display : flex; 
flex-direction : column; 
position : absolute; 
align-items : center; 
cursor : pointer; 
width : 30px; 
height : 40px; 
z-index : 2; 
} 
.span_middle {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
} 
.span_before {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
} 
.span_after {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
} 
.menu__close__btn {
display : flex; 
flex-direction : column; 
position : absolute; 
align-items : center; 
cursor : pointer; 
width : 25px; 
height : 30px; 
left : -100%; 
z-index : 2; 
} 
.span__close__left {
display : block; 
position : relative; 
width : 100%; 
height : 2px; 
margin-top : 0px; 
margin-bottom : 0px; 
background-color : black; 
transform : rotate(45deg); 
} 
.span__close__right {
display : block; 
position : relative; 
width : 100%; 
height : 2px; 
top : 0px; 
margin-top : 0px; 
margin-bottom : 0px; 
background-color : black; 
transform : rotate(135deg); 
} 
.linkhrefs {
position : fixed; 
} 
#i6gwt6 {
width : auto; 
color : #df9405; 
font-size : 16px; 
font-family : 'Baloo 2', cursive; 
} 
#ih9cnl {
color : #d983a6; 
padding : 5px; 
display : block; 
} 

  @media only screen and (max-width: 992px) {#inqxd {
width : 106.757375px; 
}} 
@media only screen and (max-width: 992px) {#ibllc {
font-size : 22px; 
}} 
@media only screen and (max-width: 992px) {#iqq4mu {
font-size : 16px; 
}} 
@media only screen and (max-width: 992px) {#ikdl4l {
font-size : 16px; 
}} 
@media only screen and (max-width: 992px) {#iqzql2 {
font-size : 16px; 
}} 
@media only screen and (max-width: 992px) {#ivdrgi {
font-size : 16px; 
}} 
@media only screen and (max-width: 992px) {#i0mmmx {
width : 329.155875px; 
}} 
@media only screen and (max-width: 992px) {#i67f {
width : 177.265625px; 
height : 177px; 
}} 
@media only screen and (max-width: 992px) {#ikwmrv {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#i4lrf {
flex-direction : column; 
justify-content : center; 
align-items : center; 
width : 90vw; 
}} 
@media only screen and (max-width: 992px) {#iuzhxd {
float : center; 
text-align : center; 
}} 
@media only screen and (max-width: 992px) {#i8subx {
width : 100%; 
}} 

  @media only screen and (max-width: 480px) {#iu87j {
width : 100%; 
text-align : center; 
display : flex; 
}} 
@media only screen and (max-width: 480px) {#iq9l {
display : none; 
}} 
@media only screen and (max-width: 480px) {#i1i8k {
width : auto; 
height : 75px; 
}} 
@media only screen and (max-width: 480px) {#i67f {
float : center; 
text-align : center; 
}} 
@media only screen and (max-width: 480px) {#iwg4f {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#iqq4mu {
font-size : 10px; 
}} 
@media only screen and (max-width: 480px) {#ikdl4l {
font-size : 10px; 
}} 
@media only screen and (max-width: 480px) {#iqzql2 {
font-size : 10px; 
}} 
@media only screen and (max-width: 480px) {#ivdrgi {
font-size : 10px; 
}} 
@media only screen and (max-width: 480px) {#i0mmmx {
width : 233.14018750000002px; 
}} 
@media only screen and (max-width: 480px) {#ii2lsw {
width : 170.71446875px; 
}} 
@media only screen and (max-width: 480px) {#i5vgyt {
width : 150.11309375px; 
}} 
@media only screen and (max-width: 480px) {#irgjju {
width : 141.49596875px; 
}} 
@media only screen and (max-width: 480px) {#issu69 {
display : none; 
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i4lrf {
width : 80vw; 
}} 
@media only screen and (max-width: 480px) {#i4aj1m {
width : 190px; 
height : 190px; 
}} 
@media only screen and (max-width: 480px) {#ij0j5r {
text-align : center; 
}} 
@media only screen and (max-width: 480px) {#ivee {
justify-content : space-between; 
}} 
@media only screen and (max-width: 480px) {.contentwrapperanimated {
display : block; 
}} 
@media only screen and (max-width: 480px) {#i9i7hn {
text-align : center; 
}} 

}
  