
#id64d169f39255464525645f12 {
  #ivee {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : space-around; 
align-items : center; 
} 
#i67f {
color : black; 
width : 252px; 
height : 251.67972500000002px; 
z-index : 10; 
margin : auto auto auto auto; 
float : right; 
} 
#iq9l {
padding : 10px; 
min-height : 100px; 
width : 76.79%; 
display : flex; 
align-items : center; 
justify-content : space-around; 
} 
#i5tql {
color : #d983a6; 
padding : 5px; 
display : block; 
} 
#i1n85 {
width : auto; 
color : #df9405; 
font-size : 16px; 
font-family : 'Baloo 2', cursive; 
} 
#if57z {
width : auto; 
color : #df9405; 
font-size : 16px; 
font-family : 'Baloo 2', cursive; 
} 
#ikkdy {
color : #d983a6; 
padding : 5px; 
display : block; 
} 
#iqceg {
width : auto; 
color : #df9405; 
font-size : 16px; 
font-family : 'Baloo 2', cursive; 
} 
#ifv1s {
color : #d983a6; 
padding : 5px; 
display : block; 
} 
#islmj {
padding : 10px; 
min-height : 41px; 
width : 35%; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#f2f7ff 0%, #f2f7ff 100%); 
border-radius : 12px 12px 12px 12px; 
display : flex; 
flex-direction : row; 
height : 60px; 
} 
.standard_button {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
text-align : center; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
display : inline-block; 
border-top-width : 1px; 
border-right-width : 1px; 
border-bottom-width : 1px; 
border-left-width : 1px; 
border-top-style : solid; 
border-right-style : solid; 
border-bottom-style : solid; 
border-left-style : solid; 
border-top-color : rgb(0, 119, 204); 
border-right-color : rgb(0, 119, 204); 
border-bottom-color : rgb(0, 119, 204); 
border-left-color : rgb(0, 119, 204); 
border-image-source : initial; 
border-image-slice : initial; 
border-image-width : initial; 
border-image-outset : initial; 
border-image-repeat : initial; 
font-size : 14px; 
border-top-left-radius : 5px; 
border-top-right-radius : 5px; 
border-bottom-right-radius : 5px; 
border-bottom-left-radius : 5px; 
margin-top : 4px; 
margin-right : 2px; 
margin-bottom : 4px; 
margin-left : 2px; 
cursor : pointer; 
color : rgb(255, 255, 255); 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#3f7696 0%, #3f7696 100%); 
} 
.standard_button:disabled  {
background-color : rgb(226, 229, 222); 
color : gray; 
} 
.standard_button:hover  {
background-image : linear-gradient(rgb(81, 169, 238), rgb(20, 123, 205)); 
border-top-color : rgb(20, 130, 208); 
border-right-color : rgb(20, 130, 208); 
border-bottom-color : rgb(20, 130, 208); 
border-left-color : rgb(20, 130, 208); 
color : rgb(255, 255, 255); 
} 
.standard_button:active  {
background-image : radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%); 
border-top-color : rgb(0, 109, 188); 
border-right-color : rgb(0, 109, 188); 
border-bottom-color : rgb(0, 109, 188); 
border-left-color : rgb(0, 109, 188); 
color : rgb(255, 255, 255); 
} 
#inqxd {
padding : 10px; 
width : 175px; 
min-height : 60px; 
display : flex; 
justify-content : space-around; 
} 
.gjs-container {
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
min-height : 100px; 
display : block; 
} 
#ijy2n {
color : black; 
width : 30px; 
height : auto; 
} 
#i6hs3 {
width : auto; 
min-height : 40px; 
display : block; 
padding : 5px 5px 5px 5px; 
} 
.swiper {
width : 100%; 
height : 300px; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
} 
.swiper.mySwiper {
height : 100%; 
padding : 0px 0px 0px 0px; 
__background-type : image; 
background-repeat : no-repeat; 
background-position : center center; 
background-attachment : scroll; 
background-size : cover; 
background-image : url('https://assetsprojects.s3.amazonaws.com/404p7nilj3gpcrg.jpg'); 
} 
.swiper-slide {
padding : 0px 0px 0px 0px; 
__background-type : image; 
background-repeat : no-repeat; 
background-position : center center; 
background-attachment : scroll; 
background-size : cover; 
background-image : url('https://assetsprojects.s3.amazonaws.com/404p7nilj3gpcrg.jpg'); 
} 
.href_button {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
text-align : center; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
} 
.href_button:disabled  {
background-color : rgb(226, 229, 222); 
color : gray; 
} 
.href_button:hover  {
background-image : linear-gradient(rgb(81, 169, 238), rgb(20, 123, 205)); 
border-top-color : rgb(20, 130, 208); 
border-right-color : rgb(20, 130, 208); 
border-bottom-color : rgb(20, 130, 208); 
border-left-color : rgb(20, 130, 208); 
color : rgb(255, 255, 255); 
} 
.href_button:active  {
background-image : radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%); 
border-top-color : rgb(0, 109, 188); 
border-right-color : rgb(0, 109, 188); 
border-bottom-color : rgb(0, 109, 188); 
border-left-color : rgb(0, 109, 188); 
color : rgb(255, 255, 255); 
} 
.swiper-button-next {
color : white; 
} 
.swiper-button-prev {
color : white; 
} 
#i7l1k {
padding : 10px; 
display : block; 
color : white; 
text-align : center; 
font-size : 13px; 
font-family : 'Baloo 2', cursive; 
} 
#ig891 {
padding : 10px; 
min-height : auto; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#3f7696 0%, #3f7696 100%); 
margin : 0px 0px 0px 0px; 
} 
#ie6sa {
padding : 10px; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#f2f7ff 0%, #f2f7ff 100%); 
} 
#iqobg {
padding : 10px; 
min-height : 100px; 
} 
#ibllc {
padding : 10px; 
display : block; 
font-size : 42px; 
font-family : 'Baloo 2', cursive; 
font-weight : 700; 
text-align : center; 
color : #14569e; 
} 
#ihuk4 {
padding : 10px; 
min-height : 100px; 
display : flex; 
flex-wrap : wrap; 
} 
#iu87j {
padding : 10px; 
min-height : 100px; 
width : 50%; 
text-align : right; 
} 
#i1i8k {
color : black; 
width : 75px; 
height : auto; 
margin : 0px 0px 0px 0px; 
z-index : 10; 
} 
#iwg4f {
padding : 10px; 
min-height : 100px; 
width : 50%; 
} 
#irgjju {
display : flex; 
align-items : center; 
padding : 0px 10px 0px 10px; 
width : 429.328125px; 
} 
#iqcul1 {
color : black; 
width : 37px; 
height : 37px; 
} 
#iqq4mu {
padding : 10px; 
display : block; 
font-family : 'Baloo 2', cursive; 
font-weight : 700; 
color : #024d67; 
font-size : 22px; 
} 
#ih8ro2 {
color : black; 
width : 37px; 
height : 37px; 
} 
#ivdrgi {
padding : 10px; 
display : block; 
font-family : 'Baloo 2', cursive; 
font-weight : 700; 
color : #024d67; 
font-size : 22px; 
} 
#i0mmmx {
display : flex; 
align-items : center; 
padding : 0px 10px 0px 10px; 
width : 429.86328125px; 
} 
#iu0rp5 {
color : black; 
width : 37px; 
height : 37px; 
} 
#iqzql2 {
padding : 10px; 
display : block; 
font-family : 'Baloo 2', cursive; 
font-weight : 700; 
color : #024d67; 
font-size : 22px; 
} 
#ii2lsw {
display : flex; 
align-items : center; 
padding : 0px 10px 0px 10px; 
width : 428.484375px; 
} 
#iae27s {
color : black; 
width : 37px; 
height : 37px; 
} 
#ikdl4l {
padding : 10px; 
display : block; 
font-weight : 700; 
color : #024d67; 
font-size : 22px; 
font-family : 'Baloo 2', cursive; 
} 
#i5vgyt {
display : flex; 
align-items : center; 
padding : 0px 10px 0px 10px; 
width : 431.12109375px; 
} 
#ipezzh {
padding : 10px; 
min-height : 100px; 
} 
#ihis35 {
min-height : 100px; 
width : 100%; 
display : flex; 
flex-direction : row; 
justify-content : center; 
align-items : center; 
} 
#ipdx1n {
padding : 10px; 
min-height : 100px; 
} 
.inputContainer {
width : 272px; 
border : 0px solid #d9d9d9; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#d9d9d9 0%, #d9d9d9 100%); 
top : auto; 
padding : 0px 0px 0px 0px; 
border-radius : 5px 5px 5px 5px; 
} 
.formInput {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
background-image : initial; 
background-position-x : initial; 
background-position-y : initial; 
background-size : initial; 
background-repeat-x : initial; 
background-repeat-y : initial; 
background-attachment : initial; 
background-origin : initial; 
background-clip : initial; 
background-color : transparent; 
border-top-width : initial; 
border-right-width : initial; 
border-left-width : initial; 
border-top-style : none; 
border-right-style : none; 
border-left-style : none; 
border-top-color : initial; 
border-right-color : initial; 
border-left-color : initial; 
border-image-source : initial; 
border-image-slice : initial; 
border-image-width : initial; 
border-image-outset : initial; 
border-image-repeat : initial; 
border-bottom-width : 1px; 
border-bottom-style : solid; 
border-bottom-color : rgb(0, 0, 0); 
width : 100%; 
border : 3px solid #d9d9d9; 
margin : 0 0 0px 0; 
align-items : center; 
border-radius : 5px 5px 5px 5px; 
} 
.gjs-row {
display : flex; 
justify-content : flex-start; 
align-items : stretch; 
flex-wrap : wrap; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
} 
.gjs-cell {
min-height : 75px; 
width : 50%; 
} 
.checkboxMain {
width : 253.5999755859375px; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
height : 47.199951171875px; 
} 
.checkboxContainer {
display : block; 
position : relative; 
padding-left : 35px; 
margin-bottom : 12px; 
cursor : pointer; 
user-select : none; 
} 
.formCheckbox {
position : absolute; 
opacity : 0; 
cursor : pointer; 
height : 0px; 
width : 0px; 
} 
.checkmark {
position : absolute; 
top : 0px; 
left : 0px; 
height : 25px; 
width : 25px; 
background-color : rgb(238, 238, 238); 
border-radius : 5px 5px 5px 5px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
border : 2px solid #1791fe; 
} 
.checkboxContainer:hover input ~ .checkmark {
background-color : rgb(204, 204, 204); 
} 
.checkboxContainer input:checked ~ .checkmark {
background-color : rgb(33, 150, 243); 
} 
#irtlk {
padding : 5px 5px 5px 5px; 
color : white; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#3f7696 0%, #3f7696 100%); 
border-radius : 10px 10px 10px 10px; 
} 
#ijfenn {
padding : 5px 5px 5px 5px; 
color : white; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#3f7696 0%, #3f7696 100%); 
border-radius : 10px 10px 10px 10px; 
} 
#ioo0tk {
padding : 10px; 
min-height : 41px; 
width : 35%; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#f2f7ff 0%, #f2f7ff 100%); 
border-radius : 12px 12px 12px 12px; 
display : none; 
flex-direction : row-reverse; 
height : 60px; 
} 
#i4lrf {
padding : 10px; 
min-height : 100px; 
__background-type : image; 
background-repeat : no-repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : cover; 
background-image : url('https://assetsprojects.s3.amazonaws.com/405opfill2jjppa.png'); 
height : 270.39996337890625px; 
border-radius : 5px 5px 0px 0px; 
display : flex; 
} 
#ibn99 {
padding : 10px; 
min-height : 100px; 
__background-type : image, color; 
background-repeat : repeat, repeat; 
background-position : left top, left top; 
background-attachment : scroll, scroll; 
background-size : auto, auto; 
background-image : url('https://assetsprojects.s3.amazonaws.com/405opfill2jltn9.png'), linear-gradient(#e5e7ee 0%, #e5e7ee 100%); 
} 
#ihhbf {
width : 40%; 
margin : 0px 0px 0px 0px; 
} 
#ibjxr {
justify-content : center; 
} 
#iw78x {
padding : 10px; 
min-height : 100px; 
width : 95%; 
} 
#ixo66 {
padding : 10px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#f2f7ff 0%, #f2f7ff 100%); 
border-radius : 15px 15px 0px 0px; 
} 
#idjo8 {
padding : 10px; 
float : center; 
justify-content : center; 
display : flex; 
font-family : 'Baloo 2', cursive; 
font-size : 24px; 
color : #16579f; 
font-weight : 200; 
} 
#is3l3 {
padding : 10px; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
} 
#il9by {
padding : 10px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#f2f7ff 0%, #f2f7ff 100%); 
border-radius : 15px 15px 0px 0px; 
} 
#icbjv {
padding : 10px; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
} 
#iflbg {
width : 60%; 
margin : 0px 0px 0px 0px; 
padding : 0 0 0 10px; 
} 
#iwon5c {
padding : 10px; 
float : center; 
justify-content : center; 
display : flex; 
font-family : 'Baloo 2', cursive; 
font-size : 24px; 
color : #16579f; 
font-weight : 200; 
} 
.crudContainerDB {
width : 100%; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
} 
#i42bof {
color : black; 
width : 100%; 
height : 100%; 
border-radius : 100% 100% 100% 100%; 
border : 6px solid #df5829; 
} 
#i4aj1m {
padding : 10px; 
width : 276px; 
height : 276px; 
border : 0px solid #df5829; 
position : relative; 
top : 4%; 
} 
#iy2iqp {
padding : 10px; 
min-height : 100px; 
width : 1071px; 
display : flex; 
flex-direction : row; 
align-items : flex-end; 
height : 112%; 
} 
#iuzhxd {
padding : 10px; 
display : block; 
color : #14569e; 
font-weight : 700; 
font-family : 'Baloo 2', cursive; 
font-size : 50px; 
} 
#iitzq {
min-height : 0px; 
} 
#i203fl {
padding : 10px; 
display : block; 
font-family : 'Baloo 2', cursive; 
font-size : 1rem; 
font-weight : 700; 
color : #df9405; 
} 
#i2548 {
min-height : 0px; 
} 
#i75eye {
padding : 10px; 
display : block; 
text-align : right; 
font-family : 'Baloo 2', cursive; 
font-weight : 100; 
color : #14569e; 
} 
#i5yzn {
padding : 0px 10px 0px 10px; 
} 
#iedyfw {
padding : 10px; 
display : block; 
font-family : 'Baloo 2', cursive; 
font-size : 1rem; 
font-weight : 700; 
color : #df9405; 
} 
#ic2557 {
min-height : 0px; 
} 
#ibzbwf {
padding : 10px; 
display : block; 
text-align : right; 
font-family : 'Baloo 2', cursive; 
font-weight : 100; 
color : #14569e; 
} 
#iyl5xx {
min-height : 0px; 
} 
#ioxaq8 {
padding : 0px 10px 0px 10px; 
} 
#ib1oa1 {
padding : 10px; 
display : block; 
font-family : 'Baloo 2', cursive; 
font-size : 1rem; 
font-weight : 700; 
color : #df9405; 
} 
#iuxqic {
min-height : 0px; 
} 
#i9v2gf {
padding : 10px; 
display : block; 
text-align : right; 
font-family : 'Baloo 2', cursive; 
font-weight : 100; 
color : #14569e; 
} 
#i3w1bh {
min-height : 0px; 
} 
#iclu9a {
padding : 0px 10px 0px 10px; 
} 
#ihzknx {
padding : 10px; 
display : block; 
font-family : 'Baloo 2', cursive; 
font-size : 1rem; 
font-weight : 700; 
color : #df9405; 
} 
#iudw9m {
min-height : 0px; 
} 
#itjfvl {
padding : 10px; 
display : block; 
text-align : right; 
font-family : 'Baloo 2', cursive; 
font-weight : 100; 
color : #14569e; 
} 
#i3rmpf {
min-height : 0px; 
} 
#iyno5t {
padding : 0px 10px 0px 10px; 
} 
#i3awwv {
padding : 10px; 
display : block; 
font-family : 'Baloo 2', cursive; 
font-size : 1rem; 
font-weight : 700; 
color : #df9405; 
} 
#ilzv0x {
min-height : 0px; 
} 
#ijlwg8 {
padding : 10px; 
display : block; 
text-align : right; 
font-family : 'Baloo 2', cursive; 
font-weight : 100; 
color : #14569e; 
} 
#i67m89 {
min-height : 0px; 
} 
#i8j17v {
padding : 0px 10px 0px 10px; 
} 
#ix22lg {
padding : 10px; 
display : block; 
font-family : 'Baloo 2', cursive; 
font-size : 1rem; 
font-weight : 700; 
color : #df9405; 
} 
#iq68dd {
min-height : 0px; 
} 
#iot82w {
padding : 10px; 
display : block; 
text-align : right; 
font-family : 'Baloo 2', cursive; 
font-weight : 100; 
color : #14569e; 
} 
#ivnhk5 {
min-height : 0px; 
} 
#i3yycr {
padding : 0px 10px 0px 10px; 
} 
#imgpk7 {
padding : 10px; 
display : block; 
font-family : 'Baloo 2', cursive; 
font-size : 1rem; 
font-weight : 700; 
color : #df9405; 
} 
#iaow34 {
min-height : 0px; 
} 
#i22nok {
padding : 10px; 
display : block; 
text-align : right; 
font-family : 'Baloo 2', cursive; 
font-weight : 100; 
color : #14569e; 
} 
#iylvv6 {
min-height : 0px; 
} 
#i1xng7 {
padding : 0px 10px 0px 10px; 
} 
#inryud {
padding : 10px; 
display : block; 
font-family : 'Baloo 2', cursive; 
font-size : 1rem; 
font-weight : 700; 
color : #df9405; 
} 
#iy6zh6 {
min-height : 0px; 
} 
#ig9kgi {
padding : 10px; 
display : block; 
text-align : right; 
font-family : 'Baloo 2', cursive; 
font-weight : 100; 
color : #14569e; 
} 
#ibxmw5 {
min-height : 0px; 
} 
#idjid7 {
padding : 0px 10px 0px 10px; 
} 
#itl5zs {
padding : 10px; 
display : block; 
font-family : 'Baloo 2', cursive; 
font-size : 1rem; 
font-weight : 700; 
color : #df9405; 
} 
#imbh5n {
min-height : 0px; 
} 
#iqppun {
padding : 10px; 
display : block; 
text-align : right; 
font-family : 'Baloo 2', cursive; 
font-weight : 100; 
color : #14569e; 
} 
#iqhyiy {
min-height : 0px; 
} 
#ibbkrj {
padding : 0px 10px 0px 10px; 
} 
#iclha5 {
padding : 10px; 
display : block; 
font-family : 'Baloo 2', cursive; 
font-size : 1rem; 
font-weight : 700; 
color : #df9405; 
max-width : 100%; 
width : 100%; 
} 
#iamufr {
min-height : 0px; 
width : 100%; 
} 
#ir8v8d {
padding : 10px; 
display : block; 
text-align : justify; 
font-family : 'Baloo 2', cursive; 
font-weight : 100; 
color : #14569e; 
} 
#ijcpw7 {
min-height : 0px; 
width : 100%; 
float : left; 
text-align : justify; 
} 
#ijoogh {
padding : 0px 10px 0px 10px; 
} 
#i6p35v {
padding : 10px; 
display : block; 
font-family : 'Baloo 2', cursive; 
font-size : 1rem; 
font-weight : 700; 
color : #df9405; 
max-width : 100%; 
width : 100%; 
} 
#igjnj2 {
min-height : 0px; 
width : 100%; 
} 
#i70idg {
padding : 10px; 
display : block; 
text-align : justify; 
font-family : 'Baloo 2', cursive; 
font-weight : 100; 
color : #14569e; 
} 
#izgukj {
min-height : 0px; 
width : 100%; 
float : left; 
text-align : justify; 
} 
#i10pai {
padding : 0px 10px 0px 10px; 
} 
#icokhl {
padding : 10px; 
display : block; 
font-family : 'Baloo 2', cursive; 
font-size : 1rem; 
font-weight : 700; 
color : #df9405; 
max-width : 100%; 
width : 100%; 
} 
#iyhkbz {
min-height : 0px; 
width : 100%; 
} 
#iq46hg {
padding : 10px; 
display : block; 
text-align : justify; 
font-family : 'Baloo 2', cursive; 
font-weight : 100; 
color : #14569e; 
} 
#i16z72 {
min-height : 0px; 
width : 100%; 
float : left; 
text-align : justify; 
} 
#i0uudf {
padding : 0px 10px 0px 10px; 
} 
#i4tbkh {
padding : 10px; 
display : block; 
font-family : 'Baloo 2', cursive; 
font-size : 1rem; 
font-weight : 700; 
color : #df9405; 
max-width : 100%; 
width : 100%; 
} 
#izl1xv {
min-height : 0px; 
width : 100%; 
} 
#igw5p4 {
padding : 10px; 
display : block; 
text-align : justify; 
font-family : 'Baloo 2', cursive; 
font-weight : 100; 
color : #14569e; 
} 
#i6ce9i {
min-height : 0px; 
width : 100%; 
float : left; 
text-align : justify; 
} 
#ijtvb7 {
padding : 0px 10px 0px 10px; 
} 
#iyfhp3 {
padding : 10px; 
display : block; 
font-family : 'Baloo 2', cursive; 
font-size : 1rem; 
font-weight : 700; 
color : #df9405; 
max-width : 100%; 
width : 100%; 
} 
#i9y9de {
min-height : 0px; 
width : 100%; 
} 
#i9akz2 {
padding : 10px; 
display : block; 
text-align : justify; 
font-family : 'Baloo 2', cursive; 
font-weight : 100; 
color : #14569e; 
} 
#i5oyrg {
min-height : 0px; 
width : 100%; 
float : left; 
text-align : justify; 
} 
#ik3z7f {
padding : 0px 10px 0px 10px; 
} 
#i86gxb {
padding : 10px; 
display : block; 
font-family : 'Baloo 2', cursive; 
font-size : 1rem; 
font-weight : 700; 
color : #df9405; 
max-width : 100%; 
width : 100%; 
} 
#ijoghn {
min-height : 0px; 
width : 100%; 
} 
#isvmzf {
padding : 10px; 
display : block; 
text-align : justify; 
font-family : 'Baloo 2', cursive; 
font-weight : 100; 
color : #14569e; 
} 
#i0lg4i {
min-height : 0px; 
width : 100%; 
float : left; 
text-align : justify; 
} 
#id03la {
padding : 0px 10px 0px 10px; 
} 
#ip7x2h {
padding : 10px; 
display : block; 
font-family : 'Baloo 2', cursive; 
font-size : 1rem; 
font-weight : 700; 
color : #df9405; 
max-width : 100%; 
width : 100%; 
} 
#ih76kg {
min-height : 0px; 
width : 100%; 
} 
#iqts5h {
padding : 10px; 
display : block; 
text-align : justify; 
font-family : 'Baloo 2', cursive; 
font-weight : 100; 
color : #14569e; 
} 
#i5m5cj {
min-height : 0px; 
width : 100%; 
float : left; 
text-align : justify; 
} 
#i0zfok {
padding : 0px 10px 0px 10px; 
} 
#i4qyex {
padding : 10px; 
display : block; 
font-family : 'Baloo 2', cursive; 
font-size : 1rem; 
font-weight : 700; 
color : #df9405; 
max-width : 100%; 
width : 100%; 
} 
#izm4ah {
min-height : 0px; 
width : 100%; 
} 
#isp30g {
padding : 10px; 
display : block; 
text-align : justify; 
font-family : 'Baloo 2', cursive; 
font-weight : 100; 
color : #14569e; 
} 
#ica702 {
min-height : 0px; 
width : 100%; 
float : left; 
text-align : justify; 
} 
#i7kr61 {
padding : 0px 10px 0px 10px; 
} 
#ityexd {
padding : 10px; 
display : block; 
font-family : 'Baloo 2', cursive; 
font-size : 1rem; 
font-weight : 700; 
color : #df9405; 
max-width : 100%; 
width : 100%; 
} 
#i1g2p5 {
min-height : 0px; 
width : 100%; 
} 
#i0htu3 {
padding : 10px; 
display : block; 
text-align : justify; 
font-family : 'Baloo 2', cursive; 
font-weight : 100; 
color : #14569e; 
} 
#ivyxmg {
min-height : 0px; 
width : 100%; 
float : left; 
text-align : justify; 
} 
#i6tdgw {
padding : 0px 10px 0px 10px; 
} 
#icjxok {
padding : 10px; 
display : block; 
font-family : 'Baloo 2', cursive; 
font-size : 1rem; 
font-weight : 700; 
color : #df9405; 
} 
#ibrp8m {
min-height : 0px; 
} 
#inz9rr {
padding : 10px; 
display : block; 
text-align : right; 
font-family : 'Baloo 2', cursive; 
font-weight : 100; 
color : #14569e; 
} 
#i6684g {
min-height : 0px; 
} 
#i29ww4 {
padding : 0px 10px 0px 10px; 
} 
#ivb27 {
font-family : 'Baloo 2', cursive; 
} 
#iqnkxh {
width : 100%; 
padding : 5px 5px 5px 5px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#f2f7ff 0%, #f2f7ff 100%); 
} 
#i1otmv {
width : 85%; 
} 
#ivlkhb {
display : block; 
} 
.contentwrapperanimated {
width : 50px; 
height : 50px; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
display : none; 
flex-direction : row; 
justify-content : center; 
align-content : center; 
align-items : center; 
} 
.container_animated {
display : flex; 
flex-direction : column; 
justify-content : center; 
align-content : center; 
align-items : center; 
width : 90%; 
height : 90%; 
} 
.menu__box {
display : block; 
position : fixed; 
top : 0px; 
left : -100%; 
width : 300px; 
height : 100vh; 
margin-top : 0px; 
margin-right : 0px; 
margin-bottom : 0px; 
margin-left : 0px; 
padding-top : 80px; 
padding-right : 0px; 
padding-bottom : 80px; 
padding-left : 0px; 
list-style-position : initial; 
list-style-image : initial; 
list-style-type : none; 
background-color : rgb(214, 164, 25); 
box-shadow : rgba(0, 0, 0, 0.4) 2px 2px 6px; 
transition-duration : 0.25s; 
z-index : 1; 
} 
.menu__item {
display : block; 
padding-top : 12px; 
padding-right : 24px; 
padding-bottom : 12px; 
padding-left : 24px; 
text-align : center; 
color : black; 
font-family : Roboto, sans-serif; 
font-size : 20px; 
font-weight : 600; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
transition-duration : 0.25s; 
} 
.menu__item:hover  {
background-color : rgb(207, 216, 220); 
} 
.menu__btn {
display : flex; 
flex-direction : column; 
position : absolute; 
align-items : center; 
cursor : pointer; 
width : 30px; 
height : 40px; 
z-index : 2; 
} 
.span_middle {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
} 
.span_before {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
} 
.span_after {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
} 
.menu__close__btn {
display : flex; 
flex-direction : column; 
position : absolute; 
align-items : center; 
cursor : pointer; 
width : 25px; 
height : 30px; 
left : -100%; 
z-index : 2; 
} 
.span__close__left {
display : block; 
position : relative; 
width : 100%; 
height : 2px; 
margin-top : 0px; 
margin-bottom : 0px; 
background-color : black; 
transform : rotate(45deg); 
} 
.span__close__right {
display : block; 
position : relative; 
width : 100%; 
height : 2px; 
top : 0px; 
margin-top : 0px; 
margin-bottom : 0px; 
background-color : black; 
transform : rotate(135deg); 
} 
.linkhrefs {
position : fixed; 
} 
#iil9au {
width : auto; 
color : #df9405; 
font-size : 16px; 
font-family : 'Baloo 2', cursive; 
} 
#ijg51n {
color : #d983a6; 
padding : 5px; 
display : block; 
} 

  @media only screen and (max-width: 992px) {#i1i8k {
height : 75px; 
width : auto; 
}} 
@media only screen and (max-width: 992px) {#inqxd {
width : 106.757375px; 
}} 
@media only screen and (max-width: 992px) {#ibllc {
font-size : 22px; 
}} 
@media only screen and (max-width: 992px) {#iqq4mu {
font-size : 16px; 
}} 
@media only screen and (max-width: 992px) {#ikdl4l {
font-size : 16px; 
}} 
@media only screen and (max-width: 992px) {#iqzql2 {
font-size : 16px; 
}} 
@media only screen and (max-width: 992px) {#ivdrgi {
font-size : 16px; 
}} 
@media only screen and (max-width: 992px) {#i0mmmx {
width : 329.155875px; 
}} 
@media only screen and (max-width: 992px) {#i67f {
width : 177.265625px; 
height : 177px; 
}} 
@media only screen and (max-width: 992px) {#iitzq {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#i2548 {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#i3rmpf {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#iudw9m {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#iuxqic {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#i3w1bh {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#ic2557 {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#iyl5xx {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#iq68dd {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#ivnhk5 {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#ibrp8m {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#i6684g {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#ilzv0x {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#i67m89 {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#iy6zh6 {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#ibxmw5 {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#iaow34 {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#iylvv6 {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#imbh5n {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#iqhyiy {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#i42bof {
height : auto; 
}} 
@media only screen and (max-width: 992px) {#i4aj1m {
height : 157.18359375px; 
}} 
@media only screen and (max-width: 992px) {#i4lrf {
height : 200.53943750000002px; 
}} 

  @media only screen and (max-width: 480px) {#iu87j {
width : 100%; 
text-align : center; 
display : flex; 
}} 
@media only screen and (max-width: 480px) {#iq9l {
display : none; 
}} 
@media only screen and (max-width: 480px) {#i1i8k {
width : 101px; 
height : 101.29340625px; 
}} 
@media only screen and (max-width: 480px) {#i67f {
float : center; 
text-align : center; 
}} 
@media only screen and (max-width: 480px) {#iwg4f {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#iqq4mu {
font-size : 10px; 
}} 
@media only screen and (max-width: 480px) {#ikdl4l {
font-size : 10px; 
}} 
@media only screen and (max-width: 480px) {#iqzql2 {
font-size : 10px; 
}} 
@media only screen and (max-width: 480px) {#ivdrgi {
font-size : 10px; 
}} 
@media only screen and (max-width: 480px) {#i0mmmx {
width : 233.14018750000002px; 
}} 
@media only screen and (max-width: 480px) {#ii2lsw {
width : 170.71446875px; 
}} 
@media only screen and (max-width: 480px) {#i5vgyt {
width : 150.11309375px; 
}} 
@media only screen and (max-width: 480px) {#irgjju {
width : 141.49596875px; 
}} 
@media only screen and (max-width: 480px) {#ioo0tk {
display : none; 
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i4aj1m {
height : 120%; 
width : 175.20001220703125px; 
}} 
@media only screen and (max-width: 480px) {#i4lrf {
flex-direction : column; 
justify-content : center; 
align-items : center; 
}} 
@media only screen and (max-width: 480px) {#ihhbf {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#iflbg {
width : 100%; 
padding : 10px 0px 0px 0px; 
}} 
@media only screen and (max-width: 480px) {#iy2iqp {
width : 212px; 
justify-content : space-around; 
}} 
@media only screen and (max-width: 480px) {.contentwrapperanimated {
display : block; 
}} 
@media only screen and (max-width: 480px) {#ivee {
justify-content : space-between; 
}} 
@media only screen and (max-width: 480px) {#iuzhxd {
width : auto; 
}} 
@media only screen and (max-width: 480px) {#ipezzh {
display : block; 
}} 

}
  